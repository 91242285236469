import React from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import actions from '../../actions';

interface Props {
  referee: Referee | undefined;
  currentUser: User | undefined;
  sendMessage: typeof actions.referee.sendRefereeMessage;
}

interface OwnState {
  isOpen: boolean;
  message: string;
}

export default class MessageModal extends React.Component<Props> {
  state: OwnState = {
    isOpen: false,
    message: '',
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  show() {
    this.setState({
      isOpen: true,
    });
  }

  onCancel() {
    this.setState({
      isOpen: false,
      message: '',
    });
  }

  onConfirm() {
    const { referee, currentUser, sendMessage } = this.props;

    const userId = referee !== undefined ? referee.user.id : undefined;

    if (currentUser === undefined) {
      console.error('User is undefined, not sending message');
      return;
    }

    if (this.state.message === '') { // TODO show feedback
      return;
    }

    sendMessage(userId, this.state.message, currentUser);

    this.setState({
      isOpen: false,
      message: '',
    });
  }

  render() {
    const user = this.props.referee !== undefined ? this.props.referee.user : undefined;

    return (
      <Modal isOpen={this.state.isOpen} toggle={() => this.toggle()}>
          <ModalHeader toggle={() => this.toggle()}>Viestin lähettäminen</ModalHeader>
          <ModalBody>
            {
              user !== undefined ?
              `Olet lähettämässä viestiä tuomarille ${user.first_name} ${user.last_name}.` :
              'Olet lähettämässä viestiä kaikille tuomareille.'
            }
            <Input
              name="message"
              type="textarea"
              value={this.state.message}
              onChange={(e) => this.setState({message: e.currentTarget.value})}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.onCancel()}>Peruuta</Button>
            <Button color="success" onClick={() => this.onConfirm()}>Lähetä</Button>
          </ModalFooter>
        </Modal>
    );
  }
}
