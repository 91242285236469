import React from 'react';

import { Col, FormGroup, Input, Label } from 'reactstrap';

interface Props {
  team: GameTeam;
  akka: string;
  pappi: string;
  onAkkaChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPappiChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const PointsForm: React.FunctionComponent<Props> = (props) => {
  const { team, pappi, akka, onPappiChange, onAkkaChange, disabled } = props;

  const akkaInt = Number.isNaN(parseInt(akka, 10)) ? 0 : parseInt(akka, 10);
  const pappiInt = Number.isNaN(parseInt(pappi, 10)) ? 0 : parseInt(pappi, 10);

  return (
    <React.Fragment>
      <h4>{team.name}</h4>
      <FormGroup row>
        <Col>
          <Input
            name="akka"
            type="number"
            value={akka}
            onChange={(e) => onAkkaChange(e)}
            disabled={disabled}
          />
        </Col>
        <Col xs="3">
          <Label for="akka">Akkaa (-2p)</Label>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Input
            name="pappi"
            type="number"
            value={pappi}
            onChange={(e) => onPappiChange(e)}
            disabled={disabled}
          />
        </Col>
        <Col xs="3">
          <Label for="pappi">Pappia (-1p)</Label>
        </Col>
      </FormGroup>
      <p>Tulos: {akkaInt * -2 + pappiInt * -1}</p>
    </React.Fragment>
  );
};

PointsForm.defaultProps = {
  disabled: false,
};

export default PointsForm;
