// tslint:disable: interface-over-type-literal

import { Action } from 'redux';

export type AsyncReduxAction<T, R = undefined> = {
  type: T,
  status: 'PENDING',
} | {
  type: T,
  status: 'SUCCESS',
  data: R,
} | {
  type: T,
  status: 'ERROR',
  error: Error,
};

export type GameUpdateAction<T> = {
  type: T,
  status: 'PENDING',
} | {
  type: T,
  status: 'SUCCESS',
  data: Game,
  response: TulosPalveluResponse,
} | {
  type: T,
  status: 'ERROR',
  data: Game,
  error: Error,
};

export type GameEndOrCancelAction<T> = {
  type: T,
  status: 'PENDING',
} | {
  type: T,
  status: 'SUCCESS',
  data: Game,
  response: TulosPalveluResponse,
} | {
  type: T,
  status: 'ERROR',
  error: Error,
};

// Auth actions

export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export type Register = AsyncReduxAction<typeof REGISTER>;
export type Login = AsyncReduxAction<typeof LOGIN>;
export type Logout = Action<typeof LOGOUT>;

// User actions

export const FETCH_USERS = 'FETCH_USERS';
export const TOGGLE_ADMIN = 'TOGGLE_ADMIN';
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const EDIT_CURRENT_USER = 'EDIT_CURRENT_USER';

export type FetchUsers = AsyncReduxAction<typeof FETCH_USERS, User[]>;
export type ToggleAdmin = AsyncReduxAction<typeof TOGGLE_ADMIN, User>;
export type FetchCurrentUser = AsyncReduxAction<typeof FETCH_CURRENT_USER, User>;
export type EditCurrentUser = AsyncReduxAction<typeof EDIT_CURRENT_USER, User>;

// Referee actions

export const FETCH_REFEREES = 'FETCH_REFEREES';
export const FETCH_REFEREE_CHOICES = 'FETCH_REFEREE_CHOICES';
export const UPDATE_REFEREE = 'UPDATE_REFEREE';
export const SEND_REFEREE_MESSAGE = 'SEND_REFEREE_MESSAGE';
export const ADD_REFEREE_GAME = 'ADD_REFEREE_GAME';

export type FetchReferees = AsyncReduxAction<typeof FETCH_REFEREES, Referee[]>;
export type FetchRefereeChoices = AsyncReduxAction<typeof FETCH_REFEREE_CHOICES, RefereeChoices>;
export type UpdateReferee = AsyncReduxAction<typeof UPDATE_REFEREE, Referee>;
export type SendRefereeMessage = AsyncReduxAction<typeof SEND_REFEREE_MESSAGE>;
export type AddRefereeGame = AsyncReduxAction<typeof ADD_REFEREE_GAME>;

// Game reporting actions

export type GameHalfName = 'firstHalf' | 'secondHalf' | 'overtime';

export const SELECT_GAME = 'SELECT_GAME';

export const SET_ERROR = 'SET_ERROR';
export const SET_WARNING = 'SET_WARNING';
export const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_GAME_QUEUE = 'FETCH_GAME_QUEUE';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';

export const GAME_START = 'GAME_START';
export const GAME_SUBMIT_FIRST_HALF = 'GAME_SUBMIT_FIRST_HALF';
export const GAME_SUBMIT_SECOND_HALF = 'GAME_SUBMIT_SECOND_HALF';
export const GAME_EDIT_HALF = 'GAME_EDIT_HALF';
export const GAME_SUBMIT_OT = 'GAME_SUBMIT_OT';
export const GAME_END = 'GAME_END';
export const GAME_CANCEL = 'GAME_CANCEL';

export const FETCH_GAME_TEAMS = 'FETCH_GAME_TEAMS';
export const DISQUALIFY_GAME_TEAM = 'DISQUALIFY_GAME_TEAM';

export type SelectGame = { type: typeof SELECT_GAME, data: string };

export type SetError = { type: typeof SET_ERROR, data: Error | undefined };
export type SetWarning = { type: typeof SET_WARNING, data: string | undefined };
export type SetInfoMessage = { type: typeof SET_INFO_MESSAGE, data: string | undefined };
export type SetSuccessMessage = { type: typeof SET_SUCCESS_MESSAGE, data: string | undefined };
export type ClearMessages = { type: typeof CLEAR_MESSAGES };

export type FetchTeams = AsyncReduxAction<typeof FETCH_TEAMS, GameTeam[]>;
export type FetchGameQueue = AsyncReduxAction<typeof FETCH_GAME_QUEUE, GameQueueGame[]>;
export type FetchMessages = AsyncReduxAction<typeof FETCH_MESSAGES, TulosPalveluMessage[]>;

export type GameStart = GameUpdateAction<typeof GAME_START>;
export type GameSubmitFirstHalf = GameUpdateAction<typeof GAME_SUBMIT_FIRST_HALF>;
export type GameSubmitSecondHalf = GameUpdateAction<typeof GAME_SUBMIT_SECOND_HALF>;
export type GameEditHalf = { type: typeof GAME_EDIT_HALF, data: Game};
export type GameSubmitOt = GameUpdateAction<typeof GAME_SUBMIT_OT>;
export type GameEnd = GameEndOrCancelAction<typeof GAME_END>;
export type GameCancel = GameEndOrCancelAction<typeof GAME_CANCEL>;

export type FetchGameTeams = AsyncReduxAction<typeof FETCH_GAME_TEAMS, GameTeam[]>;
export type DisqualifyGameTeam = AsyncReduxAction<typeof DISQUALIFY_GAME_TEAM, GameTeam>;
// Local storage actions

export const FETCH_LOCAL_GAMES = 'FETCH_LOCAL_GAMES';

interface FetchLocalGamesAction extends Action<typeof FETCH_LOCAL_GAMES> {
  data: {
    currentGames: Game[];
    finishedGames: FinishedGame[];
  };
}

export type FetchLocalGames = FetchLocalGamesAction;

// Knownaction union

export type AuthAction =
  Register |
  Login |
  Logout;

export type CurrentUserAction =
  FetchCurrentUser |
  EditCurrentUser;

export type UserAction =
  FetchUsers |
  ToggleAdmin;

export type RefereeAction =
  FetchReferees |
  FetchRefereeChoices |
  UpdateReferee |
  SendRefereeMessage |
  AddRefereeGame;

export type GameReportingAction =
  SelectGame |
  SetError |
  SetWarning |
  SetInfoMessage |
  SetSuccessMessage |
  ClearMessages |
  FetchTeams |
  FetchGameQueue |
  FetchMessages |
  GameStart |
  GameSubmitFirstHalf |
  GameSubmitSecondHalf |
  GameEditHalf |
  GameSubmitOt |
  GameEnd |
  GameCancel |
  FetchLocalGames;

export type KnownAction =
  AuthAction |
  CurrentUserAction |
  UserAction |
  RefereeAction |
  GameReportingAction |
  GameTeamAction;

export type GameTeamAction =
  FetchGameTeams |
  DisqualifyGameTeam

// TODO: redo action types
// type AsyncAction = ActionCreator<ThunkAction<void, State, void, actions.KnownAction>>;
// https://redux.js.org/recipes/usage-with-typescript/
