import React, { useEffect, useRef, useState } from 'react';

import { Alert } from 'reactstrap';
import { HttpError } from '../../util/errors';

interface Props {
  error: Error | undefined;
}

const ErrorMessage: React.FunctionComponent<Props> = (props: Props) => {
  const [visible, setVisible] = useState(true);
  const prevErrorRef = useRef<Error>();

  const { error } = props;

  useEffect(() => {
    if (error !== undefined && error !== prevErrorRef.current) {
      setVisible(true);
    }
    prevErrorRef.current = error;
  }, [props.error]);

  if (error === undefined) {
    return null;
  }

  let message: string = '';

  if (error instanceof HttpError) {
    message = error.reason;
  } else {
    message = error.message;
  }

  return (
    <Alert className="sticky" color="danger" isOpen={visible} toggle={() => setVisible(false)}>{message}</Alert>
  );
};

export default ErrorMessage;
