import { AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';

import * as actions from '../types/actions';
import waxios from './waxios';

type RegisterAction = (username: string, password: string, first_name: string,
                       last_name: string, email: string, phone: string) => void;

export const register: RegisterAction = (username: string, password: string, first_name: string,
                                         last_name: string, email: string, phone: string) =>
                                         async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.REGISTER,
    status: 'PENDING',
  });
  try {
    await waxios.post('/user', { // We're not using the result for anything
      username,
      password,
      first_name,
      last_name,
      email,
      phone,
    });

    dispatch({
      type: actions.REGISTER,
      status: 'SUCCESS',
      data: undefined, // FIXME types
    });

    // Use info to log in
    dispatch(login(username, password) as unknown as AnyAction); // FIXME types
  } catch (error) {
    dispatch({
      type: actions.REGISTER,
      status: 'ERROR',
      error,
    });
  }
};

type LoginAction = (username: string, password: string) => void;

export const login: LoginAction = (username: string, password: string) =>
    async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.LOGIN,
    status: 'PENDING',
  });
  try {
    const result: AxiosResponse<LoginResponse> = await waxios.post('/token', {
      username,
      password,
    });
    console.log(result);

    localStorage.setItem('access_token', result.data.access);
    localStorage.setItem('refresh_token', result.data.refresh);

    dispatch({
      type: actions.LOGIN,
      status: 'SUCCESS',
      data: undefined, // FIXME types
    });
  } catch (error) {
    dispatch({
      type: actions.LOGIN,
      status: 'ERROR',
      error,
    });
  }
};

type CheckLoginStateAction = () => void;

export const checkLoginState: CheckLoginStateAction = () => async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.LOGIN,
    status: 'PENDING',
  });

  const refresh = localStorage.getItem('refresh_token');

  if (refresh === null) {
    console.log('No refresh token on checkLogin');
    dispatch({
      type: actions.LOGIN,
      status: 'ERROR',
      error: undefined as unknown as Error, // We don't want to set an error here
    });
    return;
  }

  console.log('checkLoginState: Trying refresh token');

  try {
    const result: AxiosResponse<RefreshTokenResponse> = await waxios.post('token/refresh', {
      refresh,
    });
    console.log(result);
    localStorage.setItem('access_token', result.data.access);
    dispatch({
      type: actions.LOGIN,
      status: 'SUCCESS',
      data: undefined, // FIXME types
    });
  } catch (error) {
    // Token exists but is not valid in some way
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    dispatch({
      type: actions.LOGIN,
      status: 'ERROR',
      error: undefined as unknown as Error, // We don't want to set an error here
    });
  }
};

export const logout: ActionCreator<actions.KnownAction> = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');

  // We're not clearing games from localstorage, so the user may login again and continue.
  // The assumption is that only one referee will use the same browser.

  window.location.href = '/';
  return {
    type: actions.LOGOUT,
  };
};
