import React, { useState } from 'react';

import { AxiosResponse } from 'axios';
import { Alert, Button, Form } from 'reactstrap';

import waxios from '../../actions/waxios';
import ErrorMessage from './ErrorMessage';
import { ForgotPasswordInput } from './FormInput';
import SpinnerOverlay from './SpinnerOverlay';

export interface OwnState {
  email: string;
}

const requestPasswordReset = async (email: string) => {
  try {
    const result: AxiosResponse<any> = await waxios.post('/password-reset', {
      email,
    });
    console.log(result);
  } catch (error) {
    throw error;
  }
};

const ForgotPassword: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      await requestPasswordReset(email);
      setLoading(false);
      setMessage('Jos sähköpostiosoitteella löytyi käyttäjä, sähköpostiin on lähetetty linkki salasanan uusimista varten. Voit sulkea selainikkunan.');
    } catch (error) {
      setError(error);
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <SpinnerOverlay visible={loading} />
      <h2>Unohtunut salasana</h2>
      <ErrorMessage error={error}></ErrorMessage>
      {message !== '' && <Alert color="success">{message}</Alert>}
      <Form onSubmit={(e) => onSubmit(e)}>
        <ForgotPasswordInput
          name="email"
          label="Sähköposti"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          required
        />
        <Button type="submit">Resetoi salasana</Button>
      </Form>
    </div>
  );
};

export default ForgotPassword;
