import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import * as actions from '../types/actions';
import waxios from './waxios';

type FetchUsersAction = () => void;

export const fetchUsers: FetchUsersAction = () => async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.FETCH_USERS,
    status: 'PENDING',
  });
  try {
    const result: AxiosResponse<User[]> = await waxios.get('/user');
    console.log(result);

    dispatch({
      type: actions.FETCH_USERS,
      status: 'SUCCESS',
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_USERS,
      status: 'ERROR',
      error,
    });
  }
};

type ToggleAdminAction = (user: User) => void;

export const toggleAdmin: ToggleAdminAction = (user: User) => async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.TOGGLE_ADMIN,
    status: 'PENDING',
  });
  try {
    const url = `/user/${user.id}/set-admin`;
    const body: SetAdminBody = { is_staff: !user.is_staff };
    await waxios.put(url, body); // We're not using the response for anything

    dispatch({
      type: actions.TOGGLE_ADMIN,
      status: 'SUCCESS',
      data: {
        ...user,
        is_staff: !user.is_staff,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.TOGGLE_ADMIN,
      status: 'ERROR',
      error,
    });
  }
};

type FetchCurrentUserAction = () => void;

export const fetchCurrentUser: FetchCurrentUserAction = () => async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.FETCH_CURRENT_USER,
    status: 'PENDING',
  });
  try {
    const result = await waxios.get('/user/current');

    dispatch({
      type: actions.FETCH_CURRENT_USER,
      status: 'SUCCESS',
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_CURRENT_USER,
      status: 'ERROR',
      error,
    });
  }
};

type EditCurrentUser = (userId: number, userBody: UserPutBody) => void;
export const editCurrentUser: EditCurrentUser = (userId: number, userBody: UserPutBody) =>
    async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.EDIT_CURRENT_USER,
    status: 'PENDING',
  });
  try {
    const result: AxiosResponse<User> = await waxios.patch(`/user/${userId}`, {
      ...userBody,
    });

    dispatch({
      type: actions.EDIT_CURRENT_USER,
      status: 'SUCCESS',
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.EDIT_CURRENT_USER,
      status: 'ERROR',
      error,
    });
  }
};

type EditPassword = (user: User, oldPassword: string, newPassword: string) => void;
export const editPassword: EditPassword = (user: User, oldPassword: string, newPassword: string) =>
    async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.EDIT_CURRENT_USER,
    status: 'PENDING',
  });

  try {
    // TODO use a cleaner API endpoint for verifying old password
    const authResult: AxiosResponse<LoginResponse> = await waxios.post('/token', {
      username: user.username,
      password: oldPassword,
    });
    localStorage.setItem('access_token', authResult.data.access);
    localStorage.setItem('refresh_token', authResult.data.refresh);

    await waxios.patch(`/user/${user.id}`, {
      password: newPassword,
    });

    dispatch({
      type: actions.EDIT_CURRENT_USER,
      status: 'SUCCESS',
      data: user, // TODO clean up and give visual feedback
    });
  } catch (error) {
    dispatch({
      type: actions.EDIT_CURRENT_USER,
      status: 'ERROR',
      error,
    });
  }
};
