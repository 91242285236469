import React from 'react';

import { Col, Row } from 'reactstrap';

import { getTotalScores } from '../../util/gameScores';

type TeamSelection = 'team1' | 'team2' | '';

interface Props {
  game: Game | FinishedGame;
  showId?: boolean;
}

const Score: React.FunctionComponent<Props> = (props: Props) => {
  const { game } = props;
  const showId = props.showId !== undefined && props.showId;

  const isCurrentGame = (tbdGame: Game | FinishedGame): tbdGame is Game => {
    return (tbdGame as Game).firstHalf !== undefined;
  };

  let team1score: number;
  let team2score: number;

  if (isCurrentGame(game)) {
    ({ team1score, team2score } = getTotalScores(game));
  } else {
    ({ team1score, team2score } = game);
  }

  const winningTeam: TeamSelection = team1score > team2score ? 'team1' : team1score < team2score ? 'team2' : '';

  return (
    <div>
      <hr style={{margin: 0}}/>
      {showId && <h5 className="text-center">{game.id}</h5>}
      <Row>
        <Col>
          <p className={`text-center${winningTeam === 'team1' ? ' text-success' : ''}`}>{props.game.team1.name}</p>
        </Col>
        <Col>
          <p className={`text-center${winningTeam === 'team2' ? ' text-success' : ''}`}>{props.game.team2.name}</p>
        </Col>
      </Row>
      <hr style={{margin: 0}}/>
      <Row>
        <Col>
          <p className={`text-center${winningTeam === 'team1' ? ' text-success' : ''}`}>{team1score}</p>
        </Col>
        <Col>
          <p className={`text-center${winningTeam === 'team2' ? ' text-success' : ''}`}>{team2score}</p>
        </Col>
      </Row>
    </div>
  );
};

Score.defaultProps = {
  showId: true,
};

export default Score;
