import React, { useEffect, useState } from 'react';

import { Alert } from 'reactstrap';
import { HttpError } from '../../util/errors';
import sleep from '../../util/sleep';
import usePrevious from '../../util/usePrevious';

interface Props {
  error: Error | undefined;
  warning?: string | undefined;
  info?: string | undefined;
  success?: string | undefined;
  timeout?: number | 'auto';
  className?: string;
}

const Message: React.FunctionComponent<Props> = (props: Props) => {
  const [errorVisible, setErrorVisible] = useState(false);
  const [warningVisible, setWarningVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);

  const { error, warning, info, success, timeout, className: propsClassName } = props;

  const prevError = usePrevious<Error>(error);
  const prevWarning = usePrevious<string>(warning);
  const prevInfo = usePrevious<string>(info);
  const prevSuccess = usePrevious<string>(success);

  useEffect(() => {
    const shouldShowError = error !== undefined && error !== prevError;
    const shouldShowWarning = warning !== undefined && warning !== prevWarning;
    const shouldShowInfo = info !== undefined && info !== prevInfo;
    const shouldShowSuccess = success !== undefined && success !== prevSuccess;

    if ((shouldShowError === errorVisible) &&
        (shouldShowWarning === warningVisible) &&
        (shouldShowInfo === infoVisible) &&
        (shouldShowSuccess === successVisible)) {
      return;
    }

    setErrorVisible(shouldShowError || errorVisible);
    setWarningVisible(shouldShowWarning || warningVisible);
    setInfoVisible(shouldShowInfo || infoVisible);
    setSuccessVisible(shouldShowSuccess || successVisible);
  }, [props.error, props.warning, props.info, props.success]);

  let errorMessage: string = '';

  if (error instanceof HttpError) {
    errorMessage = error.reason;
  } else if (error !== undefined) {
    errorMessage = error.message;
  }

  const warningMessage = warning !== undefined ? warning : '';
  const infoMessage = info !== undefined ? info : '';
  const successMessage = success !== undefined ? success : '';
  const className = `sticky${propsClassName !== undefined ? ` ${propsClassName}` : ''}`;

  const getTimeout = (timeoutProp: number | 'auto', message: string) => {
    if (timeoutProp === 'auto') {
      return message.split(' ').length * 480; // Word count times 0,48s
    }

    return timeoutProp;
  };

  const timeOutAlert = async (delay: number, setVisible: (val: boolean) => void) => {
    await sleep(delay);
    setVisible(false);
  };

  const renderAlert = (msg: string, visible: boolean, setVisible: (val: boolean) => void, color: 'danger' | 'warning' | 'info' | 'success', timeoutProp?: number | 'auto') => {
    if (visible && timeoutProp !== undefined) {
      const timeoutMs = getTimeout(timeoutProp, msg);
      timeOutAlert(timeoutMs, setVisible);
    }

    return (
      <Alert
        color={color}
        isOpen={visible}
        toggle={() => setVisible(false)}
        onClick={() => setVisible(false)}
      >
          {msg}
      </Alert>
    );
  };

  return (
    <div className={className}>
      {renderAlert(errorMessage, errorVisible, setErrorVisible, 'danger', timeout)}
      {renderAlert(warningMessage, warningVisible, setWarningVisible, 'warning', timeout)}
      {renderAlert(infoMessage, infoVisible, setInfoVisible, 'info', timeout)}
      {renderAlert(successMessage, successVisible, setSuccessVisible, 'success', timeout)}
    </div>
  );
};

export default Message;
