import { getTotalScores } from './gameScores';

const currentGamesName = 'currentGames';
const finishedGamesName = 'finishedGames';

export const initCurrentGamesIfNotExists = () => {
  if (localStorage.getItem(currentGamesName) === null) {
    localStorage.setItem(currentGamesName, JSON.stringify([]));
  }
};

export const initFinishedGamesIfNotExists = () => {
  if (localStorage.getItem(finishedGamesName) === null) {
    localStorage.setItem(finishedGamesName, JSON.stringify([]));
  }
};

export const getCurrentGames = () => {
  const currentGames: Game[] = JSON.parse(localStorage.getItem(currentGamesName) || '[]');
  return currentGames;
};

export const saveCurrentGame = (game: Game) => {
  const currentGames = getCurrentGames();

  const index = currentGames.findIndex((savedGame) => savedGame.id === game.id);

  if (index === -1) {
    currentGames.unshift(game);
  } else {
    currentGames[index] = game;
  }

  localStorage.setItem(currentGamesName, JSON.stringify(currentGames));
};

export const deleteCurrentGame = (game: Game) => {
  const currentGames = getCurrentGames();

  const index = currentGames.findIndex((savedGame) => savedGame.id === game.id);

  if (index !== -1) {
    currentGames.splice(index, 1);
    localStorage.setItem(currentGamesName, JSON.stringify(currentGames));
  }
};

export const getFinishedGames = () => {
  const finishedGames: FinishedGame[] = JSON.parse(localStorage.getItem(finishedGamesName) || '[]');
  return finishedGames;
};

export const saveFinishedGameAndDeleteCurrent = (game: Game) => {
  const currentGames = getCurrentGames();
  const finishedGames = getFinishedGames();

  // Should exist
  const currentGameIndex = currentGames.findIndex((savedGame) => savedGame.id === game.id);

  if (currentGameIndex !== -1) {
    currentGames.splice(currentGameIndex, 1);
    localStorage.setItem(currentGamesName, JSON.stringify(currentGames));
  }

  // Should never exist
  const finishedGameIndex = finishedGames.findIndex((savedGame) => savedGame.id === game.id);

  const { team1score, team2score } = getTotalScores(game);

  const newFinishedGame: FinishedGame = {
    id: game.id,
    team1: game.team1,
    team2: game.team2,
    team1score,
    team2score,
    start: game.start,
  };

  if (finishedGameIndex === -1) {
    finishedGames.unshift(newFinishedGame);
  } else {
    finishedGames[finishedGameIndex] = newFinishedGame;
  }

  localStorage.setItem(finishedGamesName, JSON.stringify(finishedGames));
};
