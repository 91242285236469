// The holy alphabet, the source of truth and justice
const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

export const validateChecksum = (id: string): boolean => {
  // 4 digits plus letter, which is the checksum
  const regex = /^\d{4}[a-zA-Z]$/;
  if (!regex.test(id)) {
    return false;
  }

  const idNumber = parseInt(id.substring(0, 4), 10);
  const checksum = id.substring(4);

  // Validation for the checksum: id number modulo 26 should be the checksum letter, with 'a' being 0
  // Lower and upper case allowed
  const checksumIsCorrect = idNumber % 26 === alphabet.indexOf(checksum.toLocaleLowerCase());

  return checksumIsCorrect;
};

export const createCheckSummedId = (idNumber: number): string => {
  const checksum = alphabet[idNumber % 26].toUpperCase();
  return idNumber + checksum;
};
