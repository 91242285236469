import React, { useEffect, useRef, useState } from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import actions from '../../actions';
import ErrorMessage from '../main/ErrorMessage';
import Message from '../main/Message';
import BlockTable from './BlockTable';
import GameHalf from './GameHalf';
import Results from './Results';

import { tulospalveluUrl } from '../../actions/gameReporting';
import waxios from '../../actions/waxios';
import { AxiosResponse } from 'axios';

interface RouteProps {
  match: {
    params: {
      gameId: string;
    };
  };
}

interface StateProps {
  games: ReadonlyArray<Game>;
  user: Readonly<User | undefined>;
  loading: boolean;
  error: Error | undefined;
  warning: string | undefined;
  info: string | undefined;
  success: string | undefined;
}

interface DispatchProps {
  submitFirstHalf: typeof actions.gameReporting.submitFirstHalf;
  submitSecondHalf: typeof actions.gameReporting.submitSecondHalf;
  editHalf: typeof actions.gameReporting.editHalf;
  submitOt: typeof actions.gameReporting.submitOt;
  endGame: typeof actions.gameReporting.endGame;
  cancelGame: typeof actions.gameReporting.cancelGame;
  fetchLocalGames: typeof actions.gameReporting.fetchLocalGames;
  setError: typeof actions.gameReporting.setError;
  setWarning: typeof actions.gameReporting.setWarning;
  setInfo: typeof actions.gameReporting.setInfoMessage;
  setSuccess: typeof actions.gameReporting.setSuccessMessage;
}

interface Props extends RouteProps, StateProps, DispatchProps {}

const GameTabs: React.FunctionComponent<Props> = (props: Props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [gameFinished, setGameFinished] = useState(false);
  const [block, setBlock] = useState<Block|undefined>(undefined);
  const prevGamesLengthRef = useRef(0);

  const { match: { params: { gameId }}} = props;
  const { games, user, editHalf, error, warning, info, success } = props;

  useEffect(() => {
    if (games.length === 0) {
      props.fetchLocalGames();
    }

    const fetchBlock = async () => {
      const result: AxiosResponse<Block> = await waxios.get(`${tulospalveluUrl}/live/megaapi.php?block=${gameId.substring(0,4)}`);
      console.log(result.data);
      if (result.data.points.length > 1) {
        setBlock(result.data);
      }
    }

    fetchBlock();
  }, []);

  useEffect(() => {
    // If a game has been removed, it is either canceled or ended
    if (games.length < prevGamesLengthRef.current) {
      setGameFinished(true);
    }

    prevGamesLengthRef.current = games.length;
  }, [props.games]);

  const setTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  if (gameFinished) {
    return <Redirect to="/game-reporting" />;
  }

  if (user === undefined) {
    console.error('No user in Redux state');
    return (
      <Alert color="danger">Käyttäjä ei ole kirjautunut.</Alert>
    );
  }

  const activeGame = games.find((game) => game.id === gameId);

  if (activeGame === undefined) {
    const notFoundError = Error(`No game found with id ${gameId}`);
    return <ErrorMessage error={notFoundError} />;
  }

  const submitFirstHalf = (firstHalf: GameHalfResult) => {
    props.submitFirstHalf(activeGame, firstHalf, user);
  };

  const submitSecondHalf = (secondHalf: GameHalfResult) => {
    props.submitSecondHalf(activeGame, secondHalf, user);
  };

  const submitOt = (overtime: GameHalfResult) => {
    props.submitOt(activeGame, overtime, user);
  };

  return (
    <div className="container-relative">
      <h2>{`Peli: ${gameId}`}</h2>
      <Message
        className="message-overlay"
        error={error}
        warning={warning}
        info={info}
        success={success}
        timeout="auto"
      />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? 'active' : undefined}
            onClick={() => setTab('1')}
          >
            1. pääty
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : undefined}
            onClick={() => setTab('2')}
          >
            2. pääty
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '3' ? 'active' : undefined}
            onClick={() => setTab('3')}
          >
            Tulos
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <GameHalf activeGame={activeGame} half="firstHalf" onSubmit={submitFirstHalf} editHalf={editHalf} />
        </TabPane>
        <TabPane tabId="2">
          <GameHalf activeGame={activeGame} half="secondHalf" onSubmit={submitSecondHalf} editHalf={editHalf} />
        </TabPane>
        <TabPane tabId="3">
          <Results
            activeGame={activeGame}
            submitScore={() => props.endGame(activeGame, user)}
            submitOt={(half) => submitOt(half)}
            cancelGame={() => props.cancelGame(activeGame, user)}
          />
        </TabPane>
      </TabContent>
      {block && (
        <BlockTable
          scores={block.points}
          unfinishedGames={block.games.upcoming.length + block.games.ongoing.length}
          totalGames={block.games.upcoming.length + block.games.ongoing.length + block.games.finished.length} />
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  games: state.gameReporting.games,
  user: state.currentUserDetail.user,
  loading: state.gameReporting.loading,
  error: state.gameReporting.error,
  warning: state.gameReporting.warning,
  info: state.gameReporting.infoMessage,
  success: state.gameReporting.successMessage,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  submitFirstHalf: actions.gameReporting.submitFirstHalf,
  submitSecondHalf: actions.gameReporting.submitSecondHalf,
  editHalf: actions.gameReporting.editHalf,
  submitOt: actions.gameReporting.submitOt,
  endGame: actions.gameReporting.endGame,
  cancelGame: actions.gameReporting.cancelGame,
  fetchLocalGames: actions.gameReporting.fetchLocalGames,
  setError: actions.gameReporting.setError,
  setWarning: actions.gameReporting.setWarning,
  setInfo: actions.gameReporting.setInfoMessage,
  setSuccess: actions.gameReporting.setSuccessMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameTabs);
