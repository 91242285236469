import React, { useState } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { getTotalScores } from '../../util/gameScores';
import GameHalf from './GameHalf';
import Score from './Score';

interface Props {
  activeGame: Game;
  submitScore: () => void;
  submitOt: (half: GameHalfResult) => void;
  cancelGame: () => void;
}

const Results: React.FunctionComponent<Props> = (props: Props) => {
  const [overtime, setOvertime] = useState(false);
  const [modal, setModal] = useState(false);

  const { activeGame, submitScore, submitOt } = props;

  const regularGameReady = activeGame.firstHalf.ready && activeGame.secondHalf.ready;
  const { team1score, team2score } = getTotalScores(activeGame);
  const even = team1score === team2score;

  const toggleModal = () => {
    setModal(!modal);
  };

  const cancelGame = () => {
    setModal(!modal);
    props.cancelGame();
  };

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Pelin keskeytys</ModalHeader>
        <ModalBody>Oletko varma että haluat keskeyttää pelin? Toimintoa ei voi perua.</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Peruuta</Button>
          <Button color="danger" onClick={cancelGame}>Keskeytä peli</Button>
        </ModalFooter>
      </Modal>

      <h4>Tulos{!regularGameReady && ' (peli on kesken)'}</h4>
      <p>Kokonaistulokset päivittyvät aina päädyn valmistuttua.</p>
      <Score game={activeGame} showId={false} />

      {
        regularGameReady && even && !overtime &&
        <React.Fragment>
          <h4 className="text-danger">TASAPELI!</h4>
          <p>Jos peli on pudotuspeli, pelatkaa lyhyt uusinta.</p>
          <Button outline block onClick={() => setOvertime(true)}>(Vain pudotuspelit!) Pelaa lyhyt uusinta (Vain pudotuspelit!) </Button>
          <hr />
        </React.Fragment>
      }

      {
        overtime &&
        <React.Fragment>
          <h4>Lyhyt uusinta</h4>
          <p>
            Neljä kyykkätornia tasasin välein.
            Jokainen pelaaja heittää yhden kartun.
            Molemmat joukkueet pelaavat samaan päätyyn.<br />
            Jos uusinnasta tulee tasapeli, peluuta uusi lyhyt uusinta. Lähetä vain ratkaisevan uusinnan tulos.
          </p>
          <hr />
          <GameHalf
            activeGame={activeGame}
            half="overtime"
            onSubmit={(half) => submitOt(half)}
            editHalf={() => { return; }}
          />
        </React.Fragment>
      }

      {regularGameReady && !overtime && <Button outline block onClick={() => submitScore()}>Lähetä tulokset</Button>}
      <hr />
      <Button outline block color="danger" onClick={toggleModal}>Keskeytä peli</Button>
    </React.Fragment>
  );
};

export default Results;
