import React from 'react';

import CurrentUser from './CurrentUserDetail';

const CurrentUserView: React.FunctionComponent = () => (
    <div>
        <CurrentUser />
    </div>
);

export default CurrentUserView;
