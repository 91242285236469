import { Reducer } from 'redux';

import { AuthAction } from '../types/actions';
import * as actions from '../types/actions';

const initialState: AuthState = {
  loading: false,
  loggedIn: false,
  loginTried: false,
  error: undefined,
};

const reducer: Reducer<AuthState, AuthAction> =
  (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case actions.REGISTER: switch (action.status) {
      case 'PENDING': {
        return {
          ...state,
          loading: true,
          error: undefined,
        };
      }
      case 'SUCCESS': {
        return {
          ...state,
          loading: false,
        };
      }
      case 'ERROR': {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
    }
    case actions.LOGIN: switch (action.status) {
      case 'PENDING': {
        return {
          ...state,
          loading: true,
          error: undefined,
        };
      }
      case 'SUCCESS': {
        return {
          ...state,
          loading: false,
          loginTried: true,
          loggedIn: true,
        };
      }
      case 'ERROR': {
        return {
          ...state,
          loading: false,
          loginTried: true,
          error: action.error,
        };
      }
    }
    case actions.LOGOUT: {
      return {
        ...state,
        loggedIn: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
