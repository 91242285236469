import React from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Col, Row, Table } from 'reactstrap';

import actions from '../../actions';
import { TeamSearchInput } from '../main/FormInput';
import Status from '../main/Status';
import TeamListRow from './TeamListRow';

interface StateProps {
  loading: boolean;
  error: Error | undefined;
  currentUser: User | undefined;
  gameteams: ReadonlyArray<GameStatusTeam>;
}

interface DispatchProps {
  fetchGameTeams: typeof actions.team.fetchGameTeams;
  disqualifyGameTeam: typeof actions.team.disqualifyGameTeam;
}

export interface OwnState {
  nameFilter: string;
}

interface Props extends StateProps, DispatchProps {}

class TeamList extends React.Component<Props, OwnState> {
  state: OwnState = {
    nameFilter: ''
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchGameTeams(this.props.currentUser);
  }

  filterList(e: React.ChangeEvent<HTMLInputElement>) {
    const searchWord = e.currentTarget.value;
    const target = e.currentTarget.name;
    this.setState({[target]: searchWord} as Pick<OwnState, keyof OwnState>);
  }

  render(): JSX.Element {
    const { gameteams, loading, error, currentUser, disqualifyGameTeam } = this.props;
    const { nameFilter } = this.state;

    let filteredTeams = gameteams.filter(
      (t) => t.name.toLowerCase()
      .includes(this.state.nameFilter.toLowerCase()));
    let teams = filteredTeams;

    const listItems = teams.map((team: GameStatusTeam) => (
      <TeamListRow
        team={team}
        key={team.id}
        currentUser={currentUser}
        disqualifyGameTeam={disqualifyGameTeam}
      />
    ));

    // TODO: Loading spinner could be in a better place
    return (
      <div hidden={!currentUser?.is_staff}>
        <div>
          <Status loading={loading} error={error} />
          <h2>Joukkueet</h2>
          <Row>
            <Col xs="12" sm="4">
              <TeamSearchInput
                name="nameFilter"
                label="Joukkueen nimi"
                type="text"
                value={nameFilter}
                onChange={(e) => this.filterList(e)}
              />
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th>Joukkueen nimi</th>
                <th>Status</th>
                <th>Hylkää joukkue</th>
                <th />
              </tr>
            </thead>
            <tbody>{listItems}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (
  state: State,
) => ({
  loading: state.referee.loadingReferees,
  error: state.team.error,
  currentUser: state.currentUserDetail.user,
  gameteams: state.team.teams
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
    fetchGameTeams: actions.team.fetchGameTeams,
    disqualifyGameTeam: actions.team.disqualifyGameTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
