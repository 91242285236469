import React, { useState } from 'react';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

interface Props {
  choices: RefereePutChoice[] | undefined;
  value: number;
  onSelect: (value: number) => void;
}

const ChoiceDropdown: React.FunctionComponent<Props> = (props: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { choices, value, onSelect } = props;
  const current = choices !== undefined ? choices.find((choice) => choice.value === value) : undefined;

  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen((prevState) => !prevState)}>
      <DropdownToggle caret>
        {current !== undefined ? current.display_name : 'Valinta'}
      </DropdownToggle>
      <DropdownMenu>
        {choices !== undefined && choices.map((choice) => (
          <DropdownItem key={choice.value} onClick={() => onSelect(choice.value)}>{choice.display_name}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ChoiceDropdown;
