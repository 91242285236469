import React from 'react';

import RefereeList from './RefereeList';

const RefereeListView: React.FunctionComponent = () => (
  <div>
    <RefereeList />
  </div>
);

export default RefereeListView;
