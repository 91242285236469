import React from 'react';

import { Table } from 'reactstrap';

interface Props {
  scores: BlockPoints[];
  unfinishedGames: number;
  totalGames: number;
}

const BlockTable = ({ scores, unfinishedGames, totalGames }: Props) => (
  <div>
    <section className="block-title">
      <h3>Lohkon tulokset</h3>
      <p>Tulokset päivittyvät sitä mukaa kun lohkon pelit etenevät</p>
      <p>Keskeneräisiä tai aloittamattomia pelejä: {unfinishedGames}/{totalGames}</p>
    </section>
    <section className="block-table">
      <Table size="sm" responsive>
        <thead>
          <tr>
            <th />
            <th className="number">Voitot</th>
            <th className="number">Tasapelit</th>
            <th className="number">Tappiot</th>
            <th className="number">KA</th>
            <th className="number">Pts.</th>
          </tr>
        </thead>
        <tbody>
          {scores.map(team => (
            <tr key={team.team_id}>
              <td className="team-name">
                <span>{team.team_name}</span>
              </td>
              <td className="number"><span>{team.team_wins}</span></td>
              <td className="number"><span>{team.team_ties}</span></td>
              <td className="number"><span>{team.team_losses}</span></td>
              <td className="number"><span>{Math.round(team.team_average)}</span></td>
              <td className="number"><span>{team.team_points}</span></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  </div>
);

export default BlockTable;