// tslint:disable:no-shadowed-variable

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { HttpError } from '../util/errors';

interface GenericDjangoResponse {
  [key: string]: string[] | undefined;
}

type DjangoErrorResponse = GenericDjangoResponse & {
  detail?: string,
  field?: string[],
};

type DjangoAxiosError = AxiosError<DjangoErrorResponse>;

const constructError = (error: DjangoAxiosError): Error => {
  if (error.response !== undefined) {
    const res = error.response;

    const contentType: string = res.headers['content-type'];
    if (contentType.includes('text/html') || contentType.includes('text/plain')) {
      return error;
    }

    if (res.data.detail !== undefined) {
      return new HttpError(res.status, res.data.detail);
    } else if (res.data.field !== undefined) {
      // Referee endpoint: field errors
      return new HttpError(res.status, res.data.field.join('\n'));
    } else {
      // Other Django errors:
      // We don't know what properties the response object has,
      // so we'll do our best to construct a legible error message
      const message = Object.keys(res.data).map((key: string) => {
        if (res.data.hasOwnProperty(key)) {
          const value = res.data[key] as string[];
          return `${key}:\n${value.join('\n')}`;
        } else {
          return null;
        }
      }).join('\n');

      return new HttpError(res.status, message);
    }
  } else {
    if (error.message === 'Network Error' &&
        error.config.url !== undefined &&
        error.config.url.includes('tulospalvelu')) {
      if (error.config.url.includes('admin')) {
        return new Error('Tulospalveluun ei saada yhteyttä.');
      }
      return new Error('Tulospalveluun ei saada yhteyttä. Jatka pelien ilmoittamista, mutta täytä myös paperilappu ja tuo se tuomaritoimistoon.');
    }

    return error;
  }
};

const addAxiosInterceptors = (axiosInstance: AxiosInstance) => {

  // This request interceptor adds the JWT token to all requests if it exists
  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    // For absolute urls to different origins, remove some headers and omit auth token
    if (config.url !== undefined && // URL exists
        config.url.startsWith('http') && // URL is absolute, http or https
        axiosInstance.defaults.baseURL !== undefined && // waxios baseurl is defined
        !config.url.includes(axiosInstance.defaults.baseURL)) { // baseurl is not included in the request url
          delete config.headers['X-Requested-With'];
          delete config.headers['X-CSRFTOKEN'];
    } else {
      const token = localStorage.getItem('access_token');
      if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // This response interceptor will cause promises to reject on HTTP errors,
  // rather than fulfil with the error as the result. It also tries to automatically refresh the JWT access token
  // if a refresh token is available, and then fulfil the original request
  axiosInstance.interceptors.response.use((response) => {
    return response;
  }, async (error: DjangoAxiosError) => {
    if (error.config.url !== undefined && error.config.url.includes('/token')) {
      console.log('Token request failed, not retrying');
      return Promise.reject(constructError(error));
    }

    const refresh = localStorage.getItem('refresh_token');

    if (error.response !== undefined &&
        (error.response.status === 401 || error.response.status === 403) &&
        refresh !== null) {
      const origin = window.location.origin;

      // Get new access token
      try {
        const response: AxiosResponse<RefreshTokenResponse> = await axios.post(`${origin}/api/v1/token/refresh`, {
          refresh,
        });

        localStorage.setItem('access_token', response.data.access);
        // Get original request config from errored request
        const config = {
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${response.data.access}`,
          },
        };
        console.log('Retry', config);
        // Retry request with new token
        return axios(config);
      } catch (error) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        return Promise.reject(constructError(error));
      }
    }
    return Promise.reject(constructError(error));
  });
};

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1' : `https://${window.location.hostname}/api/v1`;

console.log(`waxios baseURL: ${baseURL}`);

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': 'fi',
  },
});
axiosInstance.defaults.xsrfCookieName = 'csrftoken';
axiosInstance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
addAxiosInterceptors(axiosInstance);

export default axiosInstance;
