import React, { useState } from 'react';

import { FormFeedback, FormGroup, FormText, Input, InputProps, Label } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';

import { OwnState as RefereeState } from '../admin/RefereeList';
import { OwnState as ProfileState } from './CurrentUserDetail';
import { OwnState as ForgotPasswordState } from './ForgotPassword';
import { OwnState as LoginState } from './Login';
import { OwnState as PasswordResetState } from './PasswordReset';
import { OwnState as RegisterState } from './Register';
import { OwnState as GameTeamState } from '../admin/TeamList';


export interface Props<T extends string> extends InputProps {
  name: T;
  label: string;
  value: string;
  type: InputType;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  required?: boolean;
  disabled?: boolean;
  validator?: (value: string) => boolean;
  valid?: boolean; // For the special case when validation requires more than what the component knows
  feedback?: string; // Another option would be to have the validator return both a boolean and a feedback string
  tip?: string;
}

interface InputComponent<T extends string> extends React.FunctionComponent<Props<T>> {}

export const GenericInput: InputComponent<any> = (props) => {
  const [validating, setValidating] = useState(false);

  const { name, label, value, type, onChange, validator, valid: validProp,
          required = false, disabled = false, feedback, tip, children, ...otherProps } = props;

  const onBlur = () => {
    (validator !== undefined || validProp !== undefined) && setValidating(true);
    props.onBlur !== undefined && props.onBlur();
  };

  // isValid hierarchy:
  // 'valid' prop if defined
  // 'validator' result if validator is defined
  // False otherwise
  const isValid = validProp !== undefined ? validProp : validator !== undefined ? validator(value) : false;
  // isInvalid hierarchy:
  // Negation of isValid
  // However: will be false if input is not required and currently empty
  const isInvalid = !isValid && !(!required && value === '');

  return (
    <FormGroup>
      <Label for={name}>{label}{required && <span className="text-danger"> *</span>}</Label>
      <Input
        name={name}
        id={name}
        type={type}
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
        disabled={disabled}
        onBlur={() => onBlur() }
        valid={validating && isValid}
        invalid={validating && isInvalid}
        {...otherProps}
      />
      {children}
      {required && value === '' && <FormFeedback invalid="true">Kenttä ei voi olla tyhjä</FormFeedback>}
      {
        feedback !== undefined &&
        validating &&
        isInvalid &&
        value !== '' &&
        <FormFeedback invalid="true">{feedback}</FormFeedback>
      }
      {tip !== undefined && <FormText>{tip}</FormText>}
    </FormGroup>
  );
};

export const RegisterInput: InputComponent<keyof RegisterState> = GenericInput;

export const LoginInput: InputComponent<keyof LoginState> = GenericInput;

export const ProfileInput: InputComponent<keyof ProfileState> = GenericInput;

export const ForgotPasswordInput: InputComponent<keyof ForgotPasswordState> = GenericInput;

export const PasswordResetInput: InputComponent<keyof PasswordResetState> = GenericInput;

export const RefereeSearchInput: InputComponent<keyof RefereeState> = GenericInput;

export const TeamSearchInput: InputComponent<keyof GameTeamState> = GenericInput;
