import { Reducer } from 'redux';

import { GameTeamAction } from '../types/actions';
import * as actions from '../types/actions';

const initialState: GameTeamState = {
  teams: [],
  error: undefined
};

const reducer: Reducer<GameTeamState, GameTeamAction> = (state = initialState, action: GameTeamAction): GameTeamState => {
  switch (action.type) {
    case actions.FETCH_GAME_TEAMS: switch (action.status) {
      case 'PENDING': return {
        ...state,
      };
      case 'SUCCESS': return {
        ...state,
        teams: action.data,
      };
      case 'ERROR': return {
        ...state,
        error: action.error,
      };
    }
    case actions.DISQUALIFY_GAME_TEAM: switch (action.status) {
      case 'PENDING': return {
        ...state,
      };
      case 'SUCCESS': return {
        ...state,
      };
      case 'ERROR': return {
        ...state,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default reducer;
