import React from 'react';

import FaqSection from './FaqSection';

const contacts: Array<{name: string, tg: string, phone: string}> = [
  {
    name: 'Lauri Heinonen',
    tg: '@baatuomari',
    phone: '+358 44 320 0272',
  },
  {
    name: 'Eemil Pirinen',
    tg: '@pirikiikarit',
    phone: '+358 40 482 9132',
  },
  {
    name: 'Aaro Varjus',
    tg: '@avarjus',
    phone: '+358 44 283 0228',
  }
];
const securityContacts: Array<{name: string, tg: string, phone: string}> = [
  {
    name: 'Järjestyksenvalvonta',
    tg: 'turvakettu',
    phone: '0469637405',
  }
];

const faqContent: Array<{ heading: string, body: string }> = [
  {
    heading: 'Pelaajan valitusoikeus',
    body:
'Kun joukkue huomaa vastustajan tehneen sääntöjen vastaisen heiton tai muun toimenpiteen, niin he heti tästä protestoivat ja pyytävät ratkaisua. Yksikin heitto rikkeen tapahtumisen jälkeen merkitsee valitusoikeuden menettämistä. Tuomarin pitää antaa valituksen jälkeen lupa jatkaa peliä.',
  },
  {
    heading: 'Viivyttely ja myöhästyminen',
    body:
`Jos joukkue viivyttelee heitoissa niin, että peli uhkaa venyä, käske pelaajia nopeuttamaan heittämistä rangaistuksen uhalla. Jos joukkue ei huomutuksesta huolimatta lopeta viivyttelyä, voit määrätä joukkueelle yhteisajan jonka kuluessa heittovuoron loput kartut on heitettävä (esim. 30 sek / karttu).

Jos aikataulu on venynyt jo aiemmin ja peli alkaa myöhässä, voit pelata puolikkaan pelin.

Jos joukkue on 10 minuuttia myöhässä, ilmoita asiasta heti päätuomarille. Jos kyseessä on alkulohko ja muut joukkueet ovat paikalla, voit peluuttaa pelit eri järjestyksessä aikataulun pitämiseksi.`,
  },
  {
    heading: 'Puolikas peli',
    body:
'Puolikkaassa pelissä joukkueilla on vain 2 heittovuoroa päädyn aikana, eli jokainen pelaaja heittää yhden kerran (2 karttua). Tämän jälkeen pisteet lasketaan normaalisti ja puolia vaihdetaan.',
  },
  {
    heading: 'Lyhyt uusinta (vain pudotuspeleissä)',
    body:
`Jos jatkopeleissä tulee tasapeli, pelatkaa lyhyt uusinta.

<ul>
  <li>4 tornia toisen pelineliön kyykkälinjalle metrin välein (etäisyys myös reunoista 1 m)</li>
  <li>Pelin aloittanut joukkue heittää kokonaisuudessan ensin</li>
  <li>Jokainen joukkueen pelaaja heittää yhden kartun omalta normaalilta heittoviivaltaan (ei aloitusheittoa)</li>
  <li>Laske pisteet normaalisti ja kirjaa Tuloskilkkeeseen</li>
  <li>Pystyttäkää kenttä uudelleen. Toinen joukkue pelaa nyt samaan päätyyn</li>
  <li>Jos tulos on tasan, pelatkaa uusi uusinta</li>
</ul>`,
  },
  {
    heading: 'Haljennut kyykkä',
    body:
`Tuomitse isomman puoliskon mukaan ja vaihda sen tilalle ehjä kyykkä.

Jos palat ovat yhtä suuret ja toinen on ulkona, tuomitse kyykkä ulosmenneeksi ja ota toinen pala pois. Jos molemmat (yhtä suuret) palat ovat sisällä, heittäjäjoukkue saa valita kumpi korvataan ehjällä ja kumpi poistetaan pelistä.`,
  },
  {
    heading: 'Kyykkien siirtely',
    body:
`Pelaajat eivät saa koskea kyykkiin muuten kuin heitetyllä kartulla. Jos pelaaja siirtää vahingossa heiton aikana vastustajan kyykkiä, anna varoitus. Jos pelaajan potkaisemat kyykät siirtyvät ulos (=auttaa vastustajaa), ne jäävät ulos. Jos kyykät siirtyvät pelineliössä tai kentän keskialueelle (=haittaa vastustajaa), siirrä ne takaisin siihen missä ne olivat vuoron alussa.

Jos pelaajat potkivat tai siirtelevät kyykkiä tahallaan takaisin sisään tai sisältä ulos, anna heti varoitus ja siirrä kyykät paikalleen. Jos potkiminen ei lopu kahdella varoituksella, voit määrätä potkivan pelaajan heittämään takarajalta loppupelin ajaksi.`,
  },
  {
    heading: 'Yliastuminen',
    body:
`Heiton aikana pelaaja ei saa ylittää viivaa, jolta heitto tapahtuu. Jos heitto on yliastuttu, aseta ulosmenneet kyykät takaisin pelineliön eturajalle, keskeltä aloittaen. Neliöön kaatuneet jäävät paikoilleen. Heitto katsotaan tapahtuneeksi.

Jos yliastuminen on toistuvaa ja tahallista, voit määrätä yliastujan heittämään loput kartut taaemmalta viivalta: naiset kyykkälinjalta ja miehet takarajalta.`,
  },
];

const Faq: React.FunctionComponent = () => {
  return (
    <div>
      <h2>Kinkyt tilanteet</h2>
      <p><b>Ota yhteyttä päätuomareihin:</b></p>
      {
        contacts.map((contact, index) =>
        <p key={index}>{contact.name}: <a href={`tel:${contact.phone}`}>{contact.phone}</a>, TG: {contact.tg}</p>)
      }
      <p><b>Ota yhteyttä järjestyksenvalvontaan:</b></p>
      {
        securityContacts.map((contact, index) =>
        <p key={index}>{contact.name}: <a href={`tel:${contact.phone}`}>{contact.phone}</a>, TG: {contact.tg}</p>)
      }
      {
        faqContent.map((item, index) =>
          <FaqSection key={index} index={index} heading={item.heading} body={item.body} />)
      }
    </div>
  );
};

export default Faq;
