import React, { useState } from 'react';

import { GenericInput, Props as GenericInputProps } from './FormInput';

interface Props extends GenericInputProps<string> {
  suggestions: ReadonlyArray<GameTeam>;
  onSuggestionSelect: (value: string) => void;
}

const Autocomplete: React.FunctionComponent<Props> = (props) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState<number | undefined>(undefined);

  const { suggestions, onSuggestionSelect, value, onChange, name, label, type, ...otherProps } = props;

  const filteredSuggestions = suggestions.filter((suggestion) =>
    suggestion.name.toLowerCase().includes(value.toLowerCase()) && suggestion.name !== value);

  if (activeSuggestion !== undefined && activeSuggestion > filteredSuggestions.length - 1) {
    setActiveSuggestion(filteredSuggestions.length - 1);
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    switch (e.keyCode) {
      case 38: { // Arrow up
        e.preventDefault();

        if (activeSuggestion === undefined) {
          return;
        } else if (activeSuggestion === 0) {
          setActiveSuggestion(undefined);
          return;
        }

        setActiveSuggestion(activeSuggestion - 1);
        return;
      }
      case 40: { // Arrow down
        e.preventDefault();

        if (!showSuggestions) {
          setShowSuggestions(true);
        }

        if (activeSuggestion === filteredSuggestions.length - 1 || filteredSuggestions.length === 0) {
          return;
        } else if (activeSuggestion === undefined) {
          setActiveSuggestion(0);
          return;
        }

        setActiveSuggestion(activeSuggestion + 1);
        return;
      }
      case 13: { // Enter
        e.preventDefault();

        if (activeSuggestion !== undefined) {
          onSuggestionSelect(filteredSuggestions[activeSuggestion].name);
        }
        return;
      }
      case 27: { // Escape
        setShowSuggestions(false);
        setActiveSuggestion(undefined);
        return;
      }
    }
  };

  const onClick = (e: OnClickEvent, suggestion: GameTeam, index: number) => {
    console.log(e);
    console.log(suggestion.name);
    setActiveSuggestion(index);
    onSuggestionSelect(filteredSuggestions[index].name);
    setShowSuggestions(false);

    // Synthesize and dispatch click event, not necessarily required but good form
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    const elem = document.getElementById(suggestion.id);
    elem !== null && elem.dispatchEvent(event); // Should always be fired
  };

  const onBlur = () => {
    setShowSuggestions(false);
  };

  const suggestionsListComponent = (
    <ul className="suggestions">
      {filteredSuggestions.map((suggestion, index) => {
        let className;

        if (index === activeSuggestion) {
          className = 'suggestion-active';
        }

        return (
          <li
            id={suggestion.id}
            className={className}
            key={suggestion.id}
            onMouseDown={(e: OnClickEvent) => e.preventDefault() /* hackery to prevent the textfield from blurring */}
            onMouseUp={(e: OnClickEvent) => onClick(e, suggestion, index)}
          >
              {suggestion.name}
          </li>
        );
      })}
    </ul>
  );

  return (
      <div>
        <GenericInput
          name={name}
          label={label}
          type={type}
          onFocus={() => setShowSuggestions(true)}
          onKeyDown={onKeyDown}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete="off"
          {...otherProps}
        >
          {showSuggestions && suggestionsListComponent}
        </GenericInput>
      </div>
  );
};

export default Autocomplete;
