import React from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import '../../styles/main.scss';

import actions from '../../actions';
import RefereeListView from '../admin/RefereeListView';
import TeamListView from '../admin/TeamListView';
import UserListView from '../admin/UserListView';
import Faq from '../gameReporting/Faq';
import GameTabs from '../gameReporting/GameTabs';
import GameReportingLanding from '../gameReporting/Landing';
import NewGame from '../gameReporting/NewGame';
import CurrentUserView from './CurrentUserView';
import ForgotPassword from './ForgotPassword';
import Header from './Header';
import Login from './Login';
import NotFound from './NotFound';
import PasswordReset from './PasswordReset';
import ProtectedRoute from './ProtectedRoute';
import Register from './Register';
import SpinnerOverlay from './SpinnerOverlay';

interface StateProps {
  loggedIn: boolean;
  loading: boolean;
}

interface DispatchProps {
  checkLoginState: typeof actions.auth.checkLoginState;
}

interface Props extends StateProps, DispatchProps { }

class App extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.checkLoginState();
  }

  render(): JSX.Element {
    return (
      <Router>
        <Header />
        <SpinnerOverlay visible={this.props.loading} />
        <div className="container-fluid">
          <Switch>
            <Route exact path="/">
              {this.props.loggedIn ? <Redirect to="/profile" /> : <Login />}
            </Route>
            <Route path="/register" component={Register} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/password-reset/:token" component={PasswordReset} />
            <ProtectedRoute path="/users" component={UserListView} />
            <ProtectedRoute path="/referees" component={RefereeListView} />
            <ProtectedRoute path="/teams" component={TeamListView} />
            <ProtectedRoute path="/profile" component={CurrentUserView} />
            <ProtectedRoute path="/game-reporting" exact component={GameReportingLanding} />
            <ProtectedRoute path="/game-reporting/new" component={NewGame} />
            <ProtectedRoute path="/game-reporting/faq" component={Faq} />
            <ProtectedRoute path="/game-reporting/game/:gameId" component={GameTabs} />
            <Route path="/" component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  loggedIn: state.auth.loggedIn,
  loading: state.auth.loading ||
    state.currentUserDetail.loading,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  checkLoginState: actions.auth.checkLoginState,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
