import React, { useEffect, useState } from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { NavLink as RouterNavLink, Redirect } from 'react-router-dom';
import { Button, NavLink } from 'reactstrap';

import actions from '../../actions';
import BasicCollapse from '../main/BasicCollapse';
import Score from './Score';

interface StateProps {
  user: User | undefined;
  games: ReadonlyArray<Game>;
  activeGameId: string | undefined;
  finishedGames: ReadonlyArray<FinishedGame>;
  gameQueue: ReadonlyArray<GameQueueGame>;
  messages: ReadonlyArray<TulosPalveluMessage>;
  loading: boolean;
  error: Error | undefined;
}

interface DispatchProps {
  fetchLocalGames: typeof actions.gameReporting.fetchLocalGames;
  fetchGameQueue: typeof actions.gameReporting.fetchGameQueue;
  fetchMessages: typeof actions.gameReporting.fetchMessages;
  clearMessages: typeof actions.gameReporting.clearMessages;
  startGame: typeof actions.gameReporting.startGame;
}

interface Props extends StateProps, DispatchProps {}

const GameReportingLanding: React.FunctionComponent<Props> = (props: Props) => {
  const [clickedQueueGame, setClickedQueueGame] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.games.length === 0 && props.finishedGames.length === 0) {
      props.fetchLocalGames();
    }
  }, []);

  useEffect(() => {
    if (props.user === undefined) {
      return;
    }

    props.fetchGameQueue(props.user);
    props.fetchMessages(props.user);
  }, [props.user]);

  if (props.activeGameId === clickedQueueGame && clickedQueueGame !== undefined) {
    return <Redirect to={`/game-reporting/game/${clickedQueueGame}`} />;
  }

  const { games, finishedGames, gameQueue, messages } = props;

  const onGameQueueClick = (game: GameQueueGame) => {
    // Should never happen
    if (props.user === undefined) {
      console.error('User is undefined, not starting game');
      return;
    }

    setClickedQueueGame(game.id);

    props.startGame(game.id, game.team1, game.team2, props.user);
    props.clearMessages();
  };

  const visibleMessages = messages.filter((msg) => msg.visible);
  const hiddenMessages = messages.filter((msg) => !msg.visible);

  return (
    <div>
      <h2>Tuloskilke</h2>
      {games.length !== 0 && <h4>Jatka peliä</h4>}
      {
        games.map((game, index) => (
          <NavLink key={index} tag={RouterNavLink} to={`/game-reporting/game/${game.id}`}>
            <Button outline block onClick={() => props.clearMessages()}>{`${game.id}: ${game.team1.name} vs. ${game.team2.name}`}</Button>
          </NavLink>
        ))
      }
      <h4>Uuden pelin aloittaminen</h4>
      <h5>Tulevat pelit</h5>
      {gameQueue.map((game, index) => (
        <Button key={index} outline block onClick={() => onGameQueueClick(game)}>
          {`${game.id}: ${game.team1.name} vs. ${game.team2.name}, n. klo ${game.start_time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}, kenttä ${game.court_id}`}
        </Button>
      ))}
      <hr />
      <h5>Muut vaihtoehdot</h5>
      <NavLink tag={RouterNavLink} to="/game-reporting/new">
        <Button outline block onClick={() => props.clearMessages()}>Uusi peli</Button>
      </NavLink>
      <NavLink tag={RouterNavLink} to="/game-reporting/faq">
        <Button outline block>Kinkkiset tilanteet (FAQ)</Button>
      </NavLink>
      <h4>Viestit</h4>
      {
        visibleMessages.map((msg, index) => {
          const msgHtml = `${msg.timestamp.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}<br />${msg.message}`;
          return (
            <div key={index} className="outline">
              <p dangerouslySetInnerHTML={{ __html: msgHtml }} />
            </div>
          );
        })
      }
      {
        hiddenMessages.length !== 0 &&
        <BasicCollapse
          collapsedButtonText="Näytä lisää"
          openButtonText="Näytä vähemmän"
          buttonPosition="top"
        >
          {
            hiddenMessages.map((msg, index) => {
              // TODO do this more safely, prevent complete errors at least
              const msgHtml = `${msg.timestamp.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}<br />${msg.message}`;
              return (
                <div key={index} className="outline">
                  <p dangerouslySetInnerHTML={{ __html: msgHtml }} />
                </div>
              );
            })
          }
        </BasicCollapse>
      }
      {
        finishedGames.length !== 0 &&
        <React.Fragment>
          <h4>Valmiit pelit</h4>
          <Score game={finishedGames[0]} key={0} />
        </React.Fragment>
      }
      {
        finishedGames.length > 1 &&
        <BasicCollapse
          collapsedButtonText="Näytä lisää"
          openButtonText="Näytä vähemmän"
          buttonPosition="top"
        >
          {
            finishedGames.slice(1).map((game, index) => (
              <Score game={game} key={index} />
            ))
          }
        </BasicCollapse>
      }
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  user: state.currentUserDetail.user,
  games: state.gameReporting.games,
  activeGameId: state.gameReporting.activeGameId,
  finishedGames: state.gameReporting.finishedGames,
  gameQueue: state.gameReporting.gameQueue,
  messages: state.gameReporting.tulospalveluMessages,
  loading: state.gameReporting.loading,
  error: state.gameReporting.error,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  fetchLocalGames: actions.gameReporting.fetchLocalGames,
  fetchGameQueue: actions.gameReporting.fetchGameQueue,
  fetchMessages: actions.gameReporting.fetchMessages,
  clearMessages: actions.gameReporting.clearMessages,
  startGame: actions.gameReporting.startGame,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameReportingLanding);
