export const isUserEqual = (a: User | undefined, b: User | undefined): boolean => {
  if (a === undefined || b === undefined) {
    if (a === undefined && b === undefined) {
      return true;
    } else {
      return false;
    }
  }

  return a.id === b.id &&
  a.username === b.username &&
  a.first_name === b.first_name &&
  a.last_name === b.last_name &&
  a.email === b.email &&
  a.phone === b.phone &&
  a.referee_years === b.referee_years &&
  a.is_active === b.is_active &&
  a.is_staff === b.is_staff;
};
