import { combineReducers, Reducer } from 'redux';

import auth from './auth';
import currentUserDetail from './currentUserDetail';
import gameReporting from './gameReporting';
import referee from './referee';
import user from './user';
import team from './team'

const reducer: Reducer<State> = combineReducers({
  auth,
  referee,
  user,
  currentUserDetail,
  gameReporting,
  team,
});

export default reducer;
