import React from 'react';
import TeamList from './TeamList';

const TeamListView: React.FunctionComponent = () => (
  <div>
    <TeamList/>
  </div>
);

export default TeamListView;
