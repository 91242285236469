import { Reducer } from 'redux';

import { CurrentUserAction } from '../types/actions';
import * as actions from '../types/actions';

const detailState: CurrentUserDetailState = {
    user: undefined,
    loading: false,
    error: undefined,
  };

const reducer: Reducer<CurrentUserDetailState, CurrentUserAction> =
  (state = detailState, action: CurrentUserAction): CurrentUserDetailState => {
    switch (action.type) {
      case actions.FETCH_CURRENT_USER: switch (action.status) {
        case 'PENDING': {
          return {
            ...state,
            loading: true,
          };
        }
        case 'SUCCESS': {
          return {
            ...state,
            loading: false,
            user: action.data,
          };
        }
        case 'ERROR': {
          return {
            ...state,
            loading: false,
            error: action.error,
          };
        }
        default:
          return state;
      }
      case actions.EDIT_CURRENT_USER: switch (action.status) {
        case 'PENDING': {
          return {
            ...state,
            loading: true,
          };
        }
        case 'SUCCESS': {
          return {
            ...state,
            loading: false,
            user: action.data,
          };
        }
        case 'ERROR': {
          return {
            ...state,
            loading: false,
            error: action.error,
          };
        }
        default:
          return state;
      }
      default:
        return state;
  }
};

export default reducer;
