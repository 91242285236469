import React, { useEffect, useState } from 'react';

import { Alert, Spinner as SpinnerElement } from 'reactstrap';

import { HttpError } from '../../util/errors';
import sleep from '../../util/sleep';
import usePrevious from '../../util/usePrevious';

interface Props {
  loading: boolean;
  error: Error | undefined;
}

const Status: React.FunctionComponent<Props> = (props) => {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { error, loading } = props;

  const prevError = usePrevious(error);
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    const shouldShowError = error !== undefined &&
                          error !== prevError &&
                          !loading && prevLoading !== undefined &&
                          prevLoading;
    const shouldShowSuccess = (error === prevError || error === undefined) &&
                              !loading &&
                              prevLoading !== undefined &&
                              prevLoading;

    setShowError(shouldShowError);
    setShowSuccess(shouldShowSuccess);
  }, [props.loading, props.error]);

  const waitHide = async (ms: number) => {
    await sleep(ms);
    setShowSuccess(false);
    setShowError(false);
  };

  let message: string = '';

  if (error instanceof HttpError) {
    message = error.reason;
  } else if (error !== undefined) {
    message = error.message;
  }

  if (showSuccess) {
    waitHide(500);
  }

  if (showError && error !== undefined) {
    const timeout = message.split(' ').length * 500;
    waitHide(timeout);
  }

  return (
    <React.Fragment>
      <Alert
        className="alert-sticky"
        color="danger" isOpen={showError} toggle={() => setShowError(false)}>{message}</Alert>
      {(loading || showSuccess) &&
        <div className="overlay">
          {loading && <SpinnerElement color="accent" className="spinner-large" />}
          {
            showSuccess &&
            <div className="overlay-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 38">
                <polygon points="3 18 0 22 22 38 47 3 43 0 21 31 3 18" fill="#28a745" />
              </svg>
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default Status;
