import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import actions from '../../actions';
import { GenericInput } from '../main/FormInput';

interface Props {
  referee: Referee;
  currentUser: User | undefined;
  addGame: typeof actions.referee.addRefereeGame;
}

interface OwnState {
  isOpen: boolean;
  game: string;
}

export default class AddGameModal extends React.Component<Props> {
  state: OwnState = {
    isOpen: false,
    game: '',
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  show() {
    this.setState({
      isOpen: true,
    });
  }

  onCancel() {
    this.setState({
      isOpen: false,
      message: '',
    });
  }

  onConfirm() {
    const { referee, currentUser, addGame } = this.props;
    const userId = referee.user.id;

    if (currentUser === undefined) {
      console.error('User is undefined, not adding game');
      return;
    }

    addGame(userId, this.state.game, currentUser);

    this.setState({
      isOpen: false,
      message: '',
    });
  }

  render() {
    const user = this.props.referee.user;

    return (
      <Modal isOpen={this.state.isOpen} toggle={() => this.toggle()}>
          <ModalHeader toggle={() => this.toggle()}>Pelin asettaminen</ModalHeader>
          <ModalBody>
            {`Olet asettamassa peliä tuomarille ${user.first_name} ${user.last_name}.`}
            <GenericInput
              name="message"
              label="Pelin ID"
              type="number"
              value={this.state.game}
              onChange={(e) => this.setState({game: e.currentTarget.value})}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.onCancel()}>Peruuta</Button>
            <Button color="success" onClick={() => this.onConfirm()}>Aseta</Button>
          </ModalFooter>
        </Modal>
    );
  }
}
