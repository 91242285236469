import React, { useState } from 'react';

import { Button, Collapse as ReactstrapCollapse } from 'reactstrap';

interface Props {
  collapsedButtonText: string;
  openButtonText: string;
  buttonPosition: 'top' | 'bottom';
}

const BasicCollapse: React.FunctionComponent<Props> = (props) => {
  const [isOpen, setisOpen] = useState(false);

  const { collapsedButtonText, openButtonText, buttonPosition, ...otherProps } = props;

  const renderButton = () => {
    return (
      <Button
        outline
        block
        onClick={() => setisOpen(!isOpen)}
      >
          {isOpen ? openButtonText : collapsedButtonText}
      </Button>
    );
  };

  return (
    <div
      {...otherProps}
    >
      {buttonPosition === 'top' && renderButton()}
      <ReactstrapCollapse isOpen={isOpen}>
        {props.children}
      </ReactstrapCollapse>
      {buttonPosition === 'bottom' && renderButton()}
    </div>
  );
};

export default BasicCollapse;
