import * as authActions from './auth';
import * as gameReportingActions from './gameReporting';
import * as refereeActions from './referee';
import * as userActions from './user';
import * as teamActions from './team';

export default {
  auth: authActions,
  referee: refereeActions,
  user: userActions,
  gameReporting: gameReportingActions,
  team: teamActions
};
