import { Base64 } from 'js-base64';

export const jwtDecode = (token: string): JWTPayload | undefined => {
  try {
    return JSON.parse(Base64.decode(token.split('.')[1]));
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
