// tslint:disable: max-classes-per-file

export class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'ValidationError';
  }
}

export class PropertyRequiredError extends ValidationError {
  property: string;

  constructor(property: string) {
    super(`No property: ${property}`);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'PropertyRequiredError';
    this.property = property;
  }
}

export class HttpError extends Error {
  status: number;
  reason: string;

  constructor(status: number, reason: string) {
    super(`Http error ${status}: ${reason}`);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'HttpError';
    this.status = status;
    this.reason = reason;
  }
}
