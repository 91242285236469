import React from 'react';

import { Button } from 'reactstrap';

import actions from '../../actions';

interface Props {
  team: GameStatusTeam;
  currentUser: User | undefined;
  disqualifyGameTeam: typeof actions.team.disqualifyGameTeam;
}

interface OwnState {
  name: string;
  id: string;
}

export default class TeamListRow extends React.Component<Props, OwnState> {
  state: OwnState = {
    name: '',
    id: ''
  };


  constructor(props: Props) {
    super(props);
  }

  render() {
    const { team, disqualifyGameTeam, currentUser } = this.props;
    return (
        <React.Fragment>
        <tr>
          <td>{team.name}</td>
          <td>{team.disqualified === '1' ? 'Hylätty' : 'Ei hylätty' }</td>
          <td>
            <Button
              disabled={team.disqualified === '1'}
              onClick={() => 
                {
                  if (window.confirm(`Haluatko varmasti hylätä joukkueen ${team.name}?`)){
                    disqualifyGameTeam(team, currentUser);
                  }
                }
              }
            >
              Hylkää joukkue
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}
