import { AxiosResponse } from 'axios';
import { Base64 } from 'js-base64';
import { ActionCreator, Dispatch } from 'redux';

import * as actions from '../types/actions';
import { createCheckSummedId } from '../util/gameId';
import { getTeamScore, getTotalScores } from '../util/gameScores';
import * as localData from '../util/localData';
import waxios from './waxios';

export const tulospalveluUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8012/proxy' : 'https://tulospalvelu.kyykka.fi';

const sendGameEvent = async (gameDto: GameStartRequest | GameCancelRequest | GameRequest) => {
  const body = Base64.encode(JSON.stringify(gameDto));

  return await waxios.get(`${tulospalveluUrl}/sendEvent2.php?data=${body}`);
};

export const fetchLocalGames: ActionCreator<actions.FetchLocalGames> = () => {
  const currentGames = localData.getCurrentGames();
  const finishedGames = localData.getFinishedGames();

  return {
    type: actions.FETCH_LOCAL_GAMES,
    data: {
      currentGames,
      finishedGames,
    },
  };
};

export const setError: ActionCreator<actions.SetError> = (error: Error) => {
  return {
    type: actions.SET_ERROR,
    data: error,
  };
};

export const setWarning: ActionCreator<actions.SetWarning> = (warning: string) => {
  return {
    type: actions.SET_WARNING,
    data: warning,
  };
};

export const setInfoMessage: ActionCreator<actions.SetInfoMessage> = (info: string) => {
  return {
    type: actions.SET_INFO_MESSAGE,
    data: info,
  };
};

export const setSuccessMessage: ActionCreator<actions.SetSuccessMessage> = (success: string) => {
  return {
    type: actions.SET_SUCCESS_MESSAGE,
    data: success,
  };
};

export const clearMessages: ActionCreator<actions.ClearMessages> = () => {
  return {
    type: actions.CLEAR_MESSAGES,
  };
};

type FetchTeamsAction = (teamId: string) => void;

export const fetchTeams: FetchTeamsAction = (teamId: string) => async (dispatch: Dispatch<actions.FetchTeams>) => {
  dispatch({
    type: actions.FETCH_TEAMS,
    status: 'PENDING',
  });

  try {
    const result: AxiosResponse<GameTeam[]> = await waxios.get(`${tulospalveluUrl}/tissi/teams/${teamId}`);

    dispatch({
      type: actions.FETCH_TEAMS,
      status: 'SUCCESS',
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_TEAMS,
      status: 'ERROR',
      error,
    });
  }
};

type FetchGameQueueAction = (user: User) => void;

export const fetchGameQueue: FetchGameQueueAction = (user: User) =>
    async (dispatch: Dispatch<actions.FetchGameQueue>) => {
  dispatch({
    type: actions.FETCH_GAME_QUEUE,
    status: 'PENDING',
  });

  try {
    const result: AxiosResponse<GameQueueGameDto[]> = await waxios.get(`${tulospalveluUrl}/tissi/games/${user.id}/${user.uuid}`);

    console.log(result.data);

    const gameQueue: GameQueueGame[] = result.data.map((dto) => {
      const { paper_id, team1_id, team1_name, team2_id, team2_name, start_time, league_id, court_id } = dto;

      const gameQueueGame: GameQueueGame = {
        league_id,
        court_id,
        id: createCheckSummedId(paper_id),
        start_time: new Date(start_time),
        team1: {
          id: team1_id,
          name: team1_name,
        },
        team2: {
          id: team2_id,
          name: team2_name,
        },
      };
      return gameQueueGame;
    });

    dispatch({
      type: actions.FETCH_GAME_QUEUE,
      status: 'SUCCESS',
      data: gameQueue,
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_GAME_QUEUE,
      status: 'ERROR',
      error,
    });
  }
};

type FetchMessagesAction = (user: User) => void;

export const fetchMessages: FetchMessagesAction = (user: User) => async (dispatch: Dispatch<actions.FetchMessages>) => {
  dispatch({
    type: actions.FETCH_MESSAGES,
    status: 'PENDING',
  });

  try {
    const result: AxiosResponse<TulosPalveluMessageDto[]> = await waxios.get(`${tulospalveluUrl}/tissi/messages/${user.id}/${user.uuid}`);

    const messages: TulosPalveluMessage[] = result.data.map((dto) => {
      const { visible, timestamp, ...rest } = dto;

      const message: TulosPalveluMessage = {
        ...rest,
        visible: visible === '1',
        timestamp: new Date(timestamp),
      };
      return message;
    });

    dispatch({
      type: actions.FETCH_MESSAGES,
      status: 'SUCCESS',
      data: messages,
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_MESSAGES,
      status: 'ERROR',
      error,
    });
  }
};

type StartGameAction = (id: string, team1: GameTeam, team2: GameTeam, user: User) => void;

export const startGame: StartGameAction = (id: string, team1: GameTeam, team2: GameTeam, user: User) =>
    async (dispatch: Dispatch<actions.GameStart>) => {
  dispatch({
    type: actions.GAME_START,
    status: 'PENDING',
  });

  const timestamp = new Date();

  const initialHalf: GameHalfResult = {
    ready: false,
    team1result: {
      akka: 0,
      pappi: 0,
    },
    team2result: {
      akka: 0,
      pappi: 0,
    },
  };

  const game: Game = {
    id,
    team1,
    team2,
    start: timestamp,
    ready: false,
    firstHalf: initialHalf,
    secondHalf: initialHalf,
    overtime: initialHalf,
  };

  localData.saveCurrentGame(game);

  try {
    const gameDto: GameStartRequest = {
      id,
      team1,
      team2,
      time: timestamp,
      userId: user.id,
      token: user.uuid,
      event: 'game_start',
    };

    const result: AxiosResponse<TulosPalveluResponse> = await sendGameEvent(gameDto);

    dispatch({
      type: actions.GAME_START,
      status: 'SUCCESS',
      data: game,
      response: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.GAME_START,
      status: 'ERROR',
      data: game,
      error,
    });
  }
};

type SubmitFirstHalfAction = (currentGame: Game, firstHalf: GameHalfResult, user: User) => void;

export const submitFirstHalf: SubmitFirstHalfAction =
    (currentGame: Game, firstHalf: GameHalfResult, user: User) =>
    async (dispatch: Dispatch<actions.GameSubmitFirstHalf>) => {

  dispatch({
    type: actions.GAME_SUBMIT_FIRST_HALF,
    status: 'PENDING',
  });

  const timestamp = new Date();

  const game: Game = {
    ...currentGame,
    firstHalf,
  };

  localData.saveCurrentGame(game);

  try {
    const gameDto: GameRequest = {
      id: currentGame.id,
      team1: currentGame.team1,
      team2: currentGame.team2,
      team1score: getTeamScore(firstHalf.team1result.akka, firstHalf.team1result.pappi),
      team2score: getTeamScore(firstHalf.team2result.akka, firstHalf.team2result.pappi),
      time: timestamp,
      userId: user.id,
      token: user.uuid,
      event: 'game_1st',
    };

    const result: AxiosResponse<TulosPalveluResponse> = await sendGameEvent(gameDto);

    dispatch({
      type: actions.GAME_SUBMIT_FIRST_HALF,
      status: 'SUCCESS',
      data: game,
      response: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.GAME_SUBMIT_FIRST_HALF,
      status: 'ERROR',
      data: game,
      error,
    });
  }
};

type SubmitSecondHalfAction = (currentGame: Game, secondHalf: GameHalfResult, user: User) => void;

export const submitSecondHalf: SubmitSecondHalfAction =
    (currentGame: Game, secondHalf: GameHalfResult, user: User) =>
    async (dispatch: Dispatch<actions.GameSubmitSecondHalf>) => {

  dispatch({
    type: actions.GAME_SUBMIT_SECOND_HALF,
    status: 'PENDING',
  });

  const timestamp = new Date();

  const game: Game = {
    ...currentGame,
    secondHalf,
  };

  localData.saveCurrentGame(game);

  try {
    const gameDto: GameRequest = {
      id: currentGame.id,
      team1: currentGame.team1,
      team2: currentGame.team2,
      team1score: getTeamScore(secondHalf.team1result.akka, secondHalf.team1result.pappi),
      team2score: getTeamScore(secondHalf.team2result.akka, secondHalf.team2result.pappi),
      time: timestamp,
      userId: user.id,
      token: user.uuid,
      event: 'game_2nd',
    };

    const result: AxiosResponse<TulosPalveluResponse> = await sendGameEvent(gameDto);

    dispatch({
      type: actions.GAME_SUBMIT_SECOND_HALF,
      status: 'SUCCESS',
      data: game,
      response: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.GAME_SUBMIT_SECOND_HALF,
      status: 'ERROR',
      data: game,
      error,
    });
  }
};

export const editHalf: ActionCreator<actions.GameEditHalf> = (currentGame: Game, half: actions.GameHalfName) => {
  const newGame = currentGame;
  newGame[half].ready = false;

  return {
    type: actions.GAME_EDIT_HALF,
    data: newGame,
  };
};

type SubmitOtAction = (currentGame: Game, overtime: GameHalfResult, user: User) => void;

export const submitOt: SubmitOtAction =
    (currentGame: Game, overtime: GameHalfResult, user: User) =>
    async (dispatch: Dispatch<actions.GameSubmitOt>) => {

  dispatch({
    type: actions.GAME_SUBMIT_OT,
    status: 'PENDING',
  });

  const timestamp = new Date();

  const game: Game = {
    ...currentGame,
    overtime,
  };

  // This is done here to ensure the game state will be stored locally even if the API call fails
  localData.saveCurrentGame(game);

  try {
    const { team1score, team2score } = getTotalScores(game);

    const gameReady = team1score !== team2score;

    game.ready = gameReady;
    game.overtime.ready = gameReady;

    if (gameReady) {
      localData.saveFinishedGameAndDeleteCurrent(game);
    }

    const gameDto: GameRequest = {
      id: currentGame.id,
      team1: currentGame.team1,
      team2: currentGame.team2,
      team1score,
      team2score,
      time: timestamp,
      userId: user.id,
      token: user.uuid,
      event: 'game_ot',
    };

    const result: AxiosResponse<TulosPalveluResponse> = await sendGameEvent(gameDto);

    dispatch({
      type: actions.GAME_SUBMIT_OT,
      status: 'SUCCESS',
      data: game,
      response: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.GAME_SUBMIT_OT,
      status: 'ERROR',
      data: game,
      error, // FIXME why isn't this erroring on TS?
    });
  }
};

type EndGameAction = (currentGame: Game, user: User) => void;

export const endGame: EndGameAction =
    (currentGame: Game, user: User) =>
    async (dispatch: Dispatch<actions.GameEnd>) => {

  dispatch({
    type: actions.GAME_END,
    status: 'PENDING',
  });

  try {
    const timestamp = new Date();

    const game: Game = {
      ...currentGame,
      ready: true, // Other ready states, such as overtime, may still be false
    };

    localData.saveFinishedGameAndDeleteCurrent(game);

    const { team1score, team2score } = getTotalScores(game);

    const gameDto: GameRequest = {
      id: currentGame.id,
      team1: currentGame.team1,
      team2: currentGame.team2,
      team1score,
      team2score,
      time: timestamp,
      userId: user.id,
      token: user.uuid,
      event: 'game_end',
    };

    const result: AxiosResponse<TulosPalveluResponse> = await sendGameEvent(gameDto);

    dispatch({
      type: actions.GAME_END,
      status: 'SUCCESS',
      data: game,
      response: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.GAME_END,
      status: 'ERROR',
      error,
    });
  }
};

type CancelGameAction = (currentGame: Game, user: User) => void;

export const cancelGame: CancelGameAction =
    (currentGame: Game, user: User) =>
    async (dispatch: Dispatch<actions.GameCancel>) => {

  dispatch({
    type: actions.GAME_CANCEL,
    status: 'PENDING',
  });

  try {
    const timestamp = new Date();

    localData.deleteCurrentGame(currentGame);

    const gameDto: GameCancelRequest = {
      id: currentGame.id,
      team1: currentGame.team1,
      team2: currentGame.team2,
      time: timestamp,
      userId: user.id,
      token: user.uuid,
      event: 'game_cancel',
    };

    const result: AxiosResponse<TulosPalveluResponse> = await sendGameEvent(gameDto);

    dispatch({
      type: actions.GAME_CANCEL,
      status: 'SUCCESS',
      data: currentGame,
      response: result.data,
    });
  } catch (error) {
    dispatch({
      type: actions.GAME_CANCEL,
      status: 'ERROR',
      error,
    });
  }
};
