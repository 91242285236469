import React from 'react';

import { Button, Card, CardBody, UncontrolledCollapse } from 'reactstrap';

interface Props {
  heading: string;
  body: string;
  index: number;
}

const FaqSection: React.FunctionComponent<Props> = (props) => {
  const { heading, body, index } = props;

  return (
    <React.Fragment>
      <Button outline block id={`faqToggler${index}`}>
        {heading}
      </Button>
      <UncontrolledCollapse toggler={`#faqToggler${index}`}>
        <Card>
          <CardBody dangerouslySetInnerHTML={{__html: body}} />
        </Card>
      </UncontrolledCollapse>
    </React.Fragment>
  );
};

export default FaqSection;
