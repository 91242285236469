import React, { useState } from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Alert, Button, Form } from 'reactstrap';

import actions from '../../actions';
import { gameIdValidator } from '../../util/validators';
import Autocomplete from '../main/AutoComplete';
import ErrorMessage from '../main/ErrorMessage';
import { GenericInput } from '../main/FormInput';
import Message from '../main/Message';

interface StateProps {
  teams: ReadonlyArray<GameTeam>;
  activeGameId: string;
  user: Readonly<User | undefined>;
  loading: boolean;
  error: Error | undefined;
  warning: string | undefined;
  info: string | undefined;
  success: string | undefined;
}

interface DispatchProps {
  fetchTeams: typeof actions.gameReporting.fetchTeams;
  startGame: typeof actions.gameReporting.startGame;
  setError: typeof actions.gameReporting.setError;
  setWarning: typeof actions.gameReporting.setWarning;
  setInfo: typeof actions.gameReporting.setInfoMessage;
  setSuccess: typeof actions.gameReporting.setSuccessMessage;
}

interface Props extends StateProps, DispatchProps {}

const Login: React.FunctionComponent<Props> = (props: Props) => {
  const [gameId, setGameId] = useState('');
  const [team1name, setTeam1Name] = useState('');
  const [team2name, setTeam2Name] = useState('');
  const [teamInputsDisabled, setTeamInputsDisabled] = useState(true);
  const [ownError, setOwnError] = useState<Error | undefined>(undefined);

  const { teams, user, error, warning, info, success } = props;

  // This shouldn't happen but check anyway
  if (user === undefined) {
    return (
      <Alert color="danger">Käyttäjä ei ole kirjautunut.</Alert>
    );
  }

  const handleGameIdInput = (e: FormInputEvent) => {
    const value = e.currentTarget.value;
    setGameId(value);
    gameIdValidator(value) && setTeamInputsDisabled(false);
  };

  const fetchTeams = () => {
    console.log(`Fetch teams for gameId: ${gameId}`);
    props.fetchTeams(gameId);
    setTeamInputsDisabled(false);
  };

  const startGame = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!gameIdValidator(gameId)) {
      setOwnError(new Error('Pelin ID ei ole oikein, numero ja tarkistussumma eivät täsmää'));
      return;
    }

    if (team1name === team2name) {
      setOwnError(new Error('Joukkueet eivät voi olla sama'));
      return;
    }

    let team1: GameTeam | undefined;
    let team2: GameTeam | undefined;

    // No teams which means something between Tulospalvelu and Tuloskilke is broken, mock teams and continue anyway
    if (teams.length === 0) {
      props.setWarning('Joukkueita ei voitu hakea Tulospalvelusta, mutta voit silti jatkaa pelin ilmoittamista');

      team1 = {
        id: '999',
        name: team1name,
      };
      team2 = {
        id: '999',
        name: team2name,
      };
    } else {
      team1 = teams.find((team) => team.name === team1name);
      team2 = teams.find((team) => team.name === team2name);

      if (team1 === undefined || team2 === undefined) {
        setOwnError(new Error('Toista tiimeistä ei löydy esivalinnoista, tarkista tiimien nimet'));
        return;
      }
    }

    props.startGame(gameId, team1, team2, user);
  };

  if (props.activeGameId === gameId && gameId !== '') { // TODO: smarter way of doing this?
    return <Redirect to={`/game-reporting/game/${gameId}`} />;
  }

  // TODO show loading
  return (
    <div>
      <ErrorMessage error={ownError} />
      <Message
        className="message-overlay"
        error={error}
        warning={warning}
        info={info}
        success={success}
        timeout="auto"
      />

      <Form onSubmit={(e) => startGame(e)}>
        <GenericInput
          name="gameId"
          label="Lohkon ID"
          type="text"
          value={gameId}
          onChange={handleGameIdInput}
          onBlur={fetchTeams}
          required
          validator={gameIdValidator}
          tip="Lohkon ID löytyy tuloslapusta"
          feedback="ID on 4 numeroa ja 1 kirjain, kirjain on tarkistussumma, jonka tulee täsmätä"
          autoComplete="off"
        />
        <Autocomplete
          suggestions={props.teams}
          onSuggestionSelect={setTeam1Name}
          name="team1name"
          label="Joukkue 1"
          type="text"
          value={team1name}
          onChange={(e) => setTeam1Name(e.currentTarget.value)}
          required
          disabled={teamInputsDisabled}
        />
        <Autocomplete
          suggestions={props.teams}
          onSuggestionSelect={setTeam2Name}
          name="team2name"
          label="Joukkue 2"
          type="text"
          value={team2name}
          onChange={(e) => setTeam2Name(e.currentTarget.value)}
          required
          disabled={teamInputsDisabled}
        />
        <Button type="submit">Aloita peli</Button>
      </Form>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  teams: state.gameReporting.teams,
  activeGameId: state.gameReporting.activeGameId,
  user: state.currentUserDetail.user,
  loading: state.gameReporting.loading,
  error: state.gameReporting.error,
  warning: state.gameReporting.warning,
  info: state.gameReporting.infoMessage,
  success: state.gameReporting.successMessage,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  fetchTeams: actions.gameReporting.fetchTeams,
  startGame: actions.gameReporting.startGame,
  setError: actions.gameReporting.setError,
  setWarning: actions.gameReporting.setWarning,
  setInfo: actions.gameReporting.setInfoMessage,
  setSuccess: actions.gameReporting.setSuccessMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
