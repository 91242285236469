import React from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Form } from 'reactstrap';

import actions from '../../actions';
import {
  emailValidator,
  firstNameValidator,
  lastNameValidator,
  passwordValidator,
  phoneValidator,
  repeatPasswordValidator,
  usernameValidator,
} from '../../util/validators';
import ErrorMessage from './ErrorMessage';
import { RegisterInput } from './FormInput';

interface StateProps {
  loggedIn: boolean;
  error: Error | undefined;
}

interface DispatchProps {
  register: typeof actions.auth.register;
}

interface Props extends StateProps, DispatchProps {}

export interface OwnState {
  username: string;
  password: string;
  repeatPassword: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

class Register extends React.Component<Props, OwnState> {
  state: OwnState = {
    username: '',
    password: '',
    repeatPassword: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  };

  constructor(props: Props) {
    super(props);
  }

  register(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { username, password, repeatPassword, first_name, last_name, email, phone } = this.state;

    const allFieldsValid = usernameValidator(username) &&
      passwordValidator(password) &&
      repeatPasswordValidator(password, repeatPassword) &&
      firstNameValidator(first_name) &&
      lastNameValidator(last_name) &&
      emailValidator(email) &&
      (phoneValidator(phone) || phone === '');

    if (!allFieldsValid) {
      // TODO: Create an error here and display?
      console.log('All fields not valid');
      return;
    }

    this.props.register(username, password, first_name, last_name, email, phone);
  }

  handleUserInput(e: FormInputEvent) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    // Ugly type assertion but works due to component prop checks
    this.setState({[name]: value} as Pick<OwnState, keyof OwnState>);
  }

  render() {
    if (this.props.loggedIn) {
      return <Redirect to="/profile" />;
    }

    const { username, password, repeatPassword, first_name, last_name, email, phone } = this.state;

    const { error } = this.props;

    return (
      <div>
        <h2>Rekisteröidy</h2>
        <ErrorMessage error={error} />
        <Form onSubmit={(e) => this.register(e)}>
          <RegisterInput
            name="username"
            label="Käyttäjätunnus"
            type="text"
            value={username}
            onChange={(e) => this.handleUserInput(e)}
            required
            validator={usernameValidator}
            tip="Käyttäjänimi voi sisältää numeroita, kirjaimia sekä merkkejä '@.-_'"
          />
          <RegisterInput
            name="password"
            label="Salasana"
            type="password"
            value={password}
            onChange={(e) => this.handleUserInput(e)}
            required
            validator={passwordValidator}
            tip="Salasanan tulee olla vähintään 8 merkkiä pitkä, eikä saa koostua pelkästään numeroista"
          />
          <RegisterInput
            name="repeatPassword"
            label="Salasana uudestaan"
            type="password"
            value={repeatPassword}
            onChange={(e) => this.handleUserInput(e)}
            required
            valid={repeatPasswordValidator(password, repeatPassword)}
            feedback="Salasanojen tulee täsmätä"
          />
          <RegisterInput
            name="first_name"
            label="Etunimi"
            type="text"
            value={first_name}
            onChange={(e) => this.handleUserInput(e)}
            required
            validator={firstNameValidator}
            feedback="Teksti voi olla maksimissaan 30 merkkiä pitkä"
          />
          <RegisterInput
            name="last_name"
            label="Sukunimi"
            type="text"
            value={last_name}
            onChange={(e) => this.handleUserInput(e)}
            required
            validator={lastNameValidator}
            feedback="Teksti voi olla maksimissaan 150 merkkiä pitkä"
          />
          <RegisterInput
            name="email"
            label="Sähköposti"
            type="email"
            value={email}
            onChange={(e) => this.handleUserInput(e)}
            required
            validator={emailValidator}
            feedback="Sähköpostin pitää olla oikea osoite, ja se voi maksimissaan 254 merkkiä pitkä"
          />
          <RegisterInput
            name="phone"
            label="Puhelinnumero"
            type="tel"
            value={phone}
            onChange={(e) => this.handleUserInput(e)}
            required
            validator={phoneValidator}
            tip="Puhelinnumero voi sisältää numeroita, välilyöntejä tai merkkejä '+-'"
            feedback="Puhelinnumero voi koostua vain sallituista merkeistä ja olla maksimissaan 15 merkkiä pitkä"
          />
          <Button type="submit">Rekisteröidy</Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  error: state.auth.error,
  loggedIn: state.auth.loggedIn,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  register: actions.auth.register,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
