import { useEffect, useRef } from 'react';

type UsePrevious = <T>(value: T | undefined) => T | undefined;

const usePrevious: UsePrevious = (value) => {
  const ref = useRef<typeof value>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as typeof value;
};

export default usePrevious;
