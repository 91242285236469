import React from 'react';

import { Button, Input } from 'reactstrap';

import actions from '../../actions';
import ChoiceDropdown from '../main/ChoiceDropdown';
import AddGameModal from './AddGameModal';
import MessageModal from './MessageModal';

interface Props {
  referee: Referee;
  refereeChoices: RefereeChoices;
  currentUser: User | undefined;
  updateReferee: (variableName: keyof Referee, value: string | number) => void;
  sendRefereeMessage: typeof actions.referee.sendRefereeMessage;
  addGame: typeof actions.referee.addRefereeGame;
}

interface OwnState {
  field: string;
  status: number;
  deposit: number;
  reward_given: number;
  comment: string;
}

type EnumPropertyName = 'status' | 'deposit' | 'reward_given';
type StringPropertyName = 'field' | 'comment';

export default class RefereeListRow extends React.Component<Props, OwnState> {
  state: OwnState = {
    field: this.props.referee.field !== null ? this.props.referee.field.toString() : '',
    status: this.props.referee.status,
    deposit: this.props.referee.deposit,
    reward_given: this.props.referee.reward_given,
    comment: this.props.referee.comment,
  };

  fieldInputRef: React.RefObject<HTMLInputElement>;
  commentInputRef: React.RefObject<HTMLInputElement>;
  messageModalRef: React.RefObject<MessageModal>;
  addGameModalRef: React.RefObject<AddGameModal>;

  constructor(props: Props) {
    super(props);
    this.fieldInputRef = React.createRef();
    this.commentInputRef = React.createRef();
    this.messageModalRef = React.createRef();
    this.addGameModalRef = React.createRef();
  }

  updateField() {
    const oldField = this.props.referee.field;
    const { field } = this.state;

    if (parseInt(field, 10) === oldField) {
      console.log('Field value not changed, not updating');
      return;
    }

    this.props.updateReferee('field', parseInt(field, 10));
  }

  updateComment() {
    const oldComment = this.props.referee.comment;
    const { comment } = this.state;

    if (comment === oldComment) {
      console.log('Comment value not changed, not updating');
      return;
    }

    this.props.updateReferee('comment', comment);
  }

  handleUserInput(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({[e.currentTarget.name]: e.currentTarget.value} as Pick<OwnState, StringPropertyName>);
  }

  onKeyDown(name: 'field' | 'comment', e: React.KeyboardEvent<HTMLInputElement>) {
    // Enter pressed
    if (e.keyCode === 13) {
      e.preventDefault();
      console.log('enter');

      // Blur event will trigger field update call
      if (name === 'field') {
        this.fieldInputRef.current !== null && this.fieldInputRef.current.blur();
      } else if (name === 'comment') {
        this.commentInputRef.current !== null && this.commentInputRef.current.blur();
      }
    }
  }

  handleDropdownSelect(name: EnumPropertyName, value: number) {
    const oldValue = this.props.referee[name];

    if (value === oldValue) {
      console.log('Dropdown value not changed, not updating');
      return;
    }

    // Ugly type assertion but works due to component prop checks
    this.setState({[name]: value} as Pick<OwnState, EnumPropertyName>);
    this.props.updateReferee(name, value);
  }

  render() {
    const { field, status, deposit, reward_given, comment } = this.state;
    const { referee, refereeChoices, currentUser, sendRefereeMessage, addGame } = this.props;

    return (
      <React.Fragment>
        <MessageModal
          referee={referee}
          currentUser={currentUser}
          sendMessage={sendRefereeMessage}
          ref={this.messageModalRef}
        />
        <AddGameModal
          referee={referee}
          currentUser={currentUser}
          addGame={addGame}
          ref={this.addGameModalRef}
        />

        <tr>
          <td>{referee.user.last_name}</td>
          <td>{referee.user.first_name}</td>
          <td>{referee.user.username}</td>
          <td>
            <Input
              className="input-field"
              name="field"
              type="number"
              value={field}
              onChange={(e) => this.handleUserInput(e)}
              onKeyDown={(e) => this.onKeyDown('field', e)}
              onBlur={() => this.updateField()}
              innerRef={this.fieldInputRef}
            />
          </td>
          <td>
            <ChoiceDropdown
              choices={refereeChoices.status}
              value={status}
              onSelect={(value) => this.handleDropdownSelect('status', value)}
            />
          </td>
          <td>
            <ChoiceDropdown
              choices={refereeChoices.deposit}
              value={deposit}
              onSelect={(value) => this.handleDropdownSelect('deposit', value)}
            />
          </td>
          <td>
            <ChoiceDropdown
              choices={refereeChoices.reward_given}
              value={reward_given}
              onSelect={(value) => this.handleDropdownSelect('reward_given', value)}
            />
          </td>
          <td>
            <Input
              className="input-comment"
              name="comment"
              type="text"
              value={comment}
              onChange={(e) => this.handleUserInput(e)}
              onKeyDown={(e) => this.onKeyDown('comment', e)}
              onBlur={() => this.updateComment()}
              innerRef={this.commentInputRef}
            />
          </td>
          <td>
            <Button
              onClick={() => this.messageModalRef.current !== null && this.messageModalRef.current.show()}
            >
              Lähetä viesti
            </Button>
          </td>
          <td>
            <Button
              onClick={() => this.addGameModalRef.current !== null && this.addGameModalRef.current.show()}
            >
              Aseta peli jonoon
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}
