import React from 'react';

import { Alert, Button, Form } from 'reactstrap';
import { GameHalfName } from '../../types/actions';
import PointsForm from './PointsForm';

interface Props {
  activeGame: Game;
  half: GameHalfName;
  onSubmit: (half: GameHalfResult) => void;
  editHalf: (activeGame: Game, half: GameHalfName) => void;
}

export interface OwnState {
  team1akka: string;
  team1pappi: string;
  team2akka: string;
  team2pappi: string;
}

class GameHalf extends React.Component<Props, OwnState> {
  state: OwnState = {
    team1akka: this.props.activeGame[this.props.half].team1result.akka.toString(),
    team1pappi: this.props.activeGame[this.props.half].team1result.pappi.toString(),
    team2akka: this.props.activeGame[this.props.half].team2result.akka.toString(),
    team2pappi: this.props.activeGame[this.props.half].team2result.pappi.toString(),
  };

  editHalf(activeGame: Game, half: GameHalfName) {
    this.props.editHalf(activeGame, half);
  }

  handleUserInput(e: React.ChangeEvent<HTMLInputElement>, name: keyof OwnState) {
    const value = e.currentTarget.value;

    // Ugly type assertion but works due to component prop checks
    this.setState({[name]: value} as Pick<OwnState, keyof OwnState>);
  }

  submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { team1akka, team1pappi, team2akka, team2pappi } = this.state;

    const team1akkaInt = Number.isNaN(parseInt(team1akka, 10)) ? 0 : parseInt(team1akka, 10);
    const team1pappiInt = Number.isNaN(parseInt(team1pappi, 10)) ? 0 : parseInt(team1pappi, 10);
    const team2akkaInt = Number.isNaN(parseInt(team2akka, 10)) ? 0 : parseInt(team2akka, 10);
    const team2pappiInt = Number.isNaN(parseInt(team2pappi, 10)) ? 0 : parseInt(team2pappi, 10);

    const half: GameHalfResult = {
      ready: true,
      team1result: {
        akka: team1akkaInt,
        pappi: team1pappiInt,
      },
      team2result: {
        akka: team2akkaInt,
        pappi: team2pappiInt,
      },
    };

    this.props.onSubmit(half);
  }

  render() {
    const { team1akka, team1pappi, team2akka, team2pappi } = this.state;
    const { activeGame, half } = this.props;

    // First half, second half, OT
    const buttonText = half === 'firstHalf' ? '1. pääty valmis!' : half === 'secondHalf' ? '2. pääty valmis!' : 'Lähetä tulokset';

    const isSecondHalfAndFirstNotReady = half === 'secondHalf' && !activeGame.firstHalf.ready;
    const halfIsReady = (half === 'firstHalf' && activeGame.firstHalf.ready) ||
                        (half === 'secondHalf' && activeGame.secondHalf.ready);
    const formDisabled = isSecondHalfAndFirstNotReady || halfIsReady;

    return (
      <React.Fragment>
        {
          isSecondHalfAndFirstNotReady &&
          <Alert color="warning">
            1. pääty ei ole vielä valmis. Pääset muokkaamaan tämän päädyn tietoja vasta, kun aiempi pääty on valmis
          </Alert>
        }
        {
          (halfIsReady && half !== 'overtime') &&
          <React.Fragment>
            <Button
              outline
              block
              color="warning"
              onClick={() => this.editHalf(activeGame, half)}
            >
              Muokkaa päätyä
            </Button>
          </React.Fragment>
        }
        <Form onSubmit={(e) => this.submit(e)}>
          <PointsForm
            team={activeGame.team1}
            akka={team1akka}
            pappi={team1pappi}
            onAkkaChange={(e) => this.handleUserInput(e, 'team1akka')}
            onPappiChange={(e) => this.handleUserInput(e, 'team1pappi')}
            disabled={formDisabled}
          />
          <hr />
          <PointsForm
            team={activeGame.team2}
            akka={team2akka}
            pappi={team2pappi}
            onAkkaChange={(e) => this.handleUserInput(e, 'team2akka')}
            onPappiChange={(e) => this.handleUserInput(e, 'team2pappi')}
            disabled={formDisabled}
          />
          <p>
            Akka on pelineliön sisään tai pelineliöiden väliin keskialueelle jäänyt kyykkä.<br />
            Pappi on pelineliön sivu- tai takarajalle jäänyt kyykkä.
          </p>
          <Button
            outline
            block
            type="submit"
            disabled={halfIsReady || isSecondHalfAndFirstNotReady}
          >
            {buttonText}
          </Button>
        </Form>
      </React.Fragment>
    );
  }
}

export default GameHalf;
