import React from 'react';

import UserList from './UserList';

const UserListView: React.FunctionComponent = () => (
  <div>
    <UserList />
  </div>
);

export default UserListView;
