import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AnyAction, applyMiddleware, compose, createStore, Dispatch, Middleware } from 'redux';
import thunk from 'redux-thunk';

import App from './components/main/App';
import reducers from './reducers';

export const logger: Middleware<{}, any, Dispatch<AnyAction>> = (reduxStore) => (next) => (action) => {
  console.log('dispatching', action);
  const result = next(action);
  console.log('next state', reduxStore.getState());
  return result;
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, logger)),
);

const root = document.getElementById('root') as HTMLElement;

render(
  <Provider store={store}>
    <App />
  </Provider>,
  root,
);
