import React from 'react';

import { Button } from 'reactstrap';

interface Props {
  user: User;
  onSetAdmin: (user: User) => void;
  isMe?: boolean;
}

const UserListRow: React.FunctionComponent<Props> = (props: Props) => {
  const isMe = props.isMe !== undefined && props.isMe;

  return (
    <tr>
      <td>{props.user.last_name}</td>
      <td>{props.user.first_name}</td>
      <td>{props.user.username}</td>
      <td>{props.user.email}</td>
      <td>{props.user.phone}</td>
      <td>{props.user.referee_years}</td>
      <td>{props.user.is_staff ? 'Admin' : 'Käyttäjä'}</td>
      <td>
        {
          !isMe &&
          <Button onClick={() => props.onSetAdmin(props.user)}>
            {props.user.is_staff ? 'Poista admin-oikeudet' : 'Anna admin-oikeudet'}
          </Button>
        }
      </td>
    </tr>
  );
};

UserListRow.defaultProps = {
  isMe: false,
};

export default UserListRow;
