import { validateChecksum } from './gameId';

const validate = (value: string, regex: RegExp) => {
  return regex.test(value);
};

export const usernameValidator = (username: string) => {
  // Letters, digits or @/./+/-/_
  // Max 150 caharacters
  const regex = /^[\w\d@.+\-_]{1,150}$/;
  return validate(username, regex);
};

// TODO: Django validates passwords much closelier, do the same?
export const passwordValidator = (password: string) => {
  // Not just numbers
  // Min 8 caharacters, max is arbitrary
  const regex = /(?!^\d+$)^.{8,255}$/;
  return validate(password, regex);
};

export const repeatPasswordValidator = (first: string, second: string) => {
  return first === second && second !== '';
};

export const emailValidator = (email: string) => {
  // Contains at least any character followed by @ fillowed by any character
  // Min 3, max 254 characters total
  const regex = /^(?=.{3,254}$).+@.+$/;
  return validate(email, regex);
};

export const firstNameValidator = (firstName: string) => {
  // Max 30 characters
  const regex = /^.{1,30}$/;
  return validate(firstName, regex);
};

export const lastNameValidator = (lastName: string) => {
  // Max 150 characters
  const regex = /^.{1,150}$/;
  return validate(lastName, regex);
};

export const phoneValidator = (phone: string) => {
  // Digits, whitespace or +/-
  // Max 15 characters
  const regex = /^[\d\s+-]{1,15}$/;
  return validate(phone, regex);
};

export const refereeYearsValidator = (years: string) => {
  const yearsInt = parseInt(years, 10);
  if (Number.isNaN(yearsInt)) {
    return false;
  }

  return yearsInt >= 0;
};

export const gameIdValidator = (id: string) => {
  // 4 digits plus letter, which is the checksum
  const regex = /^\d{4}[a-zA-Z]$/;
  if (!validate(id, regex)) {
    return false;
  }

  return validateChecksum(id);
};
