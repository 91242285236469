import React, { useEffect, useState } from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

import actions from '../../actions';
import { GenericInput } from '../main/FormInput';
import Status from '../main/Status';
import UserListRow from './UserListRow';

interface StateProps {
  users: ReadonlyArray<User>;
  currentUser: Readonly<User> | undefined;
  loading: boolean;
  error: Error | undefined;
}

interface DispatchProps {
  fetchUsers: typeof actions.user.fetchUsers;
  toggleAdmin: typeof actions.user.toggleAdmin;
}

interface Props extends StateProps, DispatchProps {}

const UserList: React.FunctionComponent<Props> = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [nameFilter, setNameFilter] = useState('');

  useEffect(() => {
    props.fetchUsers();
  }, []);

  const toggleModal = () => {
    setModal(!modal);
  };

  const onSetAdmin = (user: User) => {
    console.log(user.id);
    setSelectedUser(user);
    toggleModal();
  };

  const confirmSetAdmin = () => {
    if (selectedUser !== undefined) {
      props.toggleAdmin(selectedUser);
      setSelectedUser(undefined);
      toggleModal();
    }
  };

  const cancelSetAdmin = () => {
    setSelectedUser(undefined);
    toggleModal();
  };

  // FIXME loading
  const { users, currentUser, loading, error } = props;

  const filteredUsers = users.filter(
    (el) => el.first_name.toLowerCase()
      .concat(' ', el.last_name.toLowerCase(), ' ', el.username.toLowerCase(), ' ', el.email.toLowerCase())
      .includes(nameFilter.toLowerCase()));

  const listItems = filteredUsers.map((user: User) => (
    <UserListRow
      user={user}
      onSetAdmin={onSetAdmin}
      key={user.id}
      isMe={currentUser !== undefined && user.id === currentUser.id}
    />
  ));

  let modalMessage = '';
  let modalConfirmButtonMessage = '';

  if (selectedUser !== undefined) {
    modalMessage = `Oletko varma, että haluat ${selectedUser.is_staff ? 'poistaa käyttäjältä' : 'antaa käyttäjälle'} ${selectedUser.first_name} ${selectedUser.last_name} admin-oikeudet?`;
    modalConfirmButtonMessage = selectedUser.is_staff ? 'Poista oikeudet' : 'Anna oikeudet';
  }

  return (
    <React.Fragment>
      <Status loading={loading} error={error} />

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Admin-oikeuksien muutos</ModalHeader>
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={cancelSetAdmin}>Peruuta</Button>
          <Button color="success" onClick={confirmSetAdmin}>{modalConfirmButtonMessage}</Button>
        </ModalFooter>
      </Modal>

      <h2>Käyttäjät</h2>

      <Row>
        <Col xs="12" sm="4">
          <GenericInput
            name="nameFilter"
            label="Haku"
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.currentTarget.value)}
          />
        </Col>
      </Row>

      <Table>
        <thead>
          <tr>
            <th>Sukunimi</th>
            <th>Etunimi</th>
            <th>Käyttäjätunnus</th>
            <th>Sähköposti</th>
            <th>Puhelin</th>
            <th>Tuomarointikerrat</th>
            <th>Käyttöoikeudet</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {listItems}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  users: state.user.users,
  currentUser: state.currentUserDetail.user,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  fetchUsers: actions.user.fetchUsers,
  toggleAdmin: actions.user.toggleAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
