import { AxiosResponse } from 'axios';
import { Base64 } from 'js-base64';
import { Dispatch } from 'redux';

import * as actions from '../types/actions';
import waxios from './waxios';

const tulospalveluUrl = 'https://tulospalvelu.kyykka.fi';
const liveKyykkaUrl = 'https://live.kyykka.fi/api/'


type FetchGameTeamsAction = (user: User | undefined) => void;

export const fetchGameTeams: FetchGameTeamsAction = (user) => async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.FETCH_GAME_TEAMS,
    status: 'PENDING',
  });
  try {

    if(user?.is_staff){

      const result: AxiosResponse<GameStatusTeam[]> = await waxios.get(`${tulospalveluUrl}/tissi/teams/all`);
      //const result: AxiosResponse<[GameTeam[], String[]]> = await waxios.get(`${liveKyykkaUrl}/teams/all`);
      //let teams: { id: string, name: string, disqualified: string }[] = [{ "id": "1", "name": "TTYMI", 'disqualified': '1' }, { "id": "3", "name": "Tietokilta 4", 'disqualified': '0'}]
      dispatch({
        type: actions.FETCH_GAME_TEAMS,
        status: 'SUCCESS',
        data: result.data,
      //  data: teams, 
      });
    }
    return [];

  } catch (error){
        dispatch({
            type: actions.FETCH_GAME_TEAMS,
            status: 'ERROR',
            error,
        });
    };

};

type DisqualifyGameTeamAction = (team: GameStatusTeam, user: User | undefined) => void;

export const disqualifyGameTeam: DisqualifyGameTeamAction = (team, user) =>
  async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.DISQUALIFY_GAME_TEAM,
    status: 'PENDING',
  });


  
  try {
    if (user?.is_staff){
      console.log(`Disqualify team ${team.name} with id: ${team.id}`)
      
      const dto = {
        jid: user?.id,
        juuid: user?.uuid,
        team_id: team.id,
      };

      const data = Base64.encode(JSON.stringify(dto));

      // Kutsutaan tulospalvelua tässä
      // https://tulospalvelu.kyykka.fi/admin/disqualify.php?jid=tuomari-id&juuid=tuomarin-uuid&team_id=666

      const res = await waxios.get(`${tulospalveluUrl}/admin/disqualify.php?data=${data}`);
	console.log(res)
      dispatch({
        type: actions.DISQUALIFY_GAME_TEAM,
        status: 'SUCCESS',
        data: team,
      });
    }
    //const url = `/referee/${referee.id}`;
    //await waxios.patch(url, {
    //  [variableName]: value,
    //});
    return [];

  } catch (error) {
    dispatch({
      type: actions.DISQUALIFY_GAME_TEAM,
      status: 'ERROR',
      error,
    });
  }
};
