import { Reducer } from 'redux';

import { GameReportingAction } from '../types/actions';
import * as actions from '../types/actions';
import { getTotalScores } from '../util/gameScores';

// Utility functions

const updateCurrentGame = (state: Readonly<GameReportingState>, newGame: Readonly<Game>): Game[] => {
  const newGames = state.games.slice();
  const index = newGames.findIndex((game) => game.id === newGame.id);

  if (index === -1) {
    console.error(`Game with ID ${newGame.id} not found in state`);
    return newGames;
  }

  newGames[index] = newGame;

  return newGames;
};

const removeCurrentGame = (state: Readonly<GameReportingState>, game: Readonly<Game>):
    { newGames: Game[], newActiveGameId: string } => {
  const newGames = state.games.slice();
  const index = newGames.findIndex((currentGame) => currentGame.id === game.id);

  if (index === -1) {
    console.error(`Game with ID ${game.id} not found in state`);
    return { newGames, newActiveGameId: state.activeGameId };
  }

  newGames.splice(index, 1);

  let newActiveGameId = state.activeGameId;
  if (newActiveGameId === game.id) {
    newActiveGameId = '';
  }

  return { newGames, newActiveGameId };
};

const addFinishedGame = (state: Readonly<GameReportingState>, game: Readonly<Game>): FinishedGame[] => {
  const { team1score, team2score } = getTotalScores(game);
  const finishedGame: FinishedGame = {
    id: game.id,
    team1: game.team1,
    team2: game.team2,
    team1score,
    team2score,
    start: game.start,
  };

  const newFinishedGames = state.finishedGames.slice();
  newFinishedGames.unshift(finishedGame);

  let newActiveGameId = state.activeGameId;
  if (newActiveGameId === game.id) {
    newActiveGameId = '';
  }

  return newFinishedGames;
};

const createMessages = (state: Readonly<GameReportingState>, response: TulosPalveluResponse) => {
  const isSuccess = response.status === 'ok';
  const warning = isSuccess ? state.warning : response.message;
  const successMessage = isSuccess ? response.message : state.successMessage;

  return { warning, successMessage };
};

// Reducer

const initialState: GameReportingState = {
  games: [],
  finishedGames: [],
  activeGameId: '',
  teams: [],
  gameQueue: [],
  tulospalveluMessages: [],
  loading: false,
  error: undefined,
  warning: undefined,
  infoMessage: undefined,
  successMessage: undefined,
};

const reducer: Reducer<GameReportingState, GameReportingAction> =
  (state = initialState, action: GameReportingAction): GameReportingState => {
  switch (action.type) {
    case actions.FETCH_LOCAL_GAMES: return {
      ...state,
      games: action.data.currentGames,
      finishedGames: action.data.finishedGames,
    };
    case actions.SELECT_GAME: {
      if (state.games.findIndex((game) => game.id === action.data) !== -1) {
        console.error(`Game Id ${action.data} does not exist in Redux state`);
        return state;
      }

      return {
        ...state,
        activeGameId: action.data,
      };
    }
    case actions.SET_ERROR: return {
      ...state,
      error: action.data,
    };
    case actions.SET_WARNING: return {
      ...state,
      warning: action.data,
    };
    case actions.SET_INFO_MESSAGE: return {
      ...state,
      infoMessage: action.data,
    };
    case actions.SET_SUCCESS_MESSAGE: return {
      ...state,
      successMessage: action.data,
    };
    case actions.CLEAR_MESSAGES: return {
      ...state,
      error: undefined,
      warning: undefined,
      infoMessage: undefined,
      successMessage: undefined,
    };
    case actions.FETCH_TEAMS: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': return {
        ...state,
        loading: false,
        teams: action.data,
      };
      case 'ERROR': return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case actions.FETCH_GAME_QUEUE: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': return {
        ...state,
        loading: false,
        gameQueue: action.data,
      };
      case 'ERROR': return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case actions.FETCH_MESSAGES: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': return {
        ...state,
        loading: false,
        tulospalveluMessages: action.data,
      };
      case 'ERROR': return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case actions.GAME_START: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': {
        const { warning, successMessage } = createMessages(state, action.response);

        return {
          ...state,
          loading: false,
          games: state.games.concat(action.data),
          activeGameId: action.data.id,
          warning,
          successMessage,
        };
      }
      case 'ERROR': return {
        ...state,
        loading: false,
        games: state.games.concat(action.data),
        activeGameId: action.data.id,
        error: action.error,
      };
    }
    case actions.GAME_SUBMIT_FIRST_HALF: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': {
        const game = action.data;
        const newGames = updateCurrentGame(state, game);

        const { warning, successMessage } = createMessages(state, action.response);

        return {
          ...state,
          loading: false,
          games: newGames,
          warning,
          successMessage,
        };
      }
      case 'ERROR': {
        const game = action.data;
        const newGames = updateCurrentGame(state, game);

        return {
          ...state,
          loading: false,
          games: newGames,
          error: action.error,
        };
      }
    }
    case actions.GAME_SUBMIT_SECOND_HALF: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': {
        const game = action.data;
        const newGames = updateCurrentGame(state, game);

        const { warning, successMessage } = createMessages(state, action.response);

        return {
          ...state,
          loading: false,
          games: newGames,
          warning,
          successMessage,
        };
      }
      case 'ERROR': {
        const game = action.data;
        const newGames = updateCurrentGame(state, game);

        return {
          ...state,
          loading: false,
          games: newGames,
          error: action.error,
        };
      }
    }
    case actions.GAME_EDIT_HALF: {
      const newGames = updateCurrentGame(state, action.data);

      return {
        ...state,
        games: newGames,
      };
    }
    case actions.GAME_SUBMIT_OT: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': {
        const game = action.data;

        if (game.ready) {
          const { newGames, newActiveGameId } = removeCurrentGame(state, game);

          const newFinishedGames = addFinishedGame(state, game);

          const { warning, successMessage } = createMessages(state, action.response);

          return {
            ...state,
            loading: false,
            games: newGames,
            finishedGames: newFinishedGames,
            activeGameId: newActiveGameId,
            warning,
            successMessage,
          };
        } else {
          const newGames = updateCurrentGame(state, game);

          const { warning, successMessage } = createMessages(state, action.response);

          return {
            ...state,
            loading: false,
            games: newGames,
            warning,
            successMessage,
          };
        }
      }
      case 'ERROR': {
        const game = action.data;
        const newGames = updateCurrentGame(state, game);

        return {
          ...state,
          loading: false,
          games: newGames,
          error: action.error,
        };
      }
    }
    case actions.GAME_END: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': {
        const game = action.data;

        const { newGames, newActiveGameId } = removeCurrentGame(state, game);

        const newFinishedGames = addFinishedGame(state, game);

        const { warning, successMessage } = createMessages(state, action.response);

        return {
          ...state,
          loading: false,
          games: newGames,
          finishedGames: newFinishedGames,
          activeGameId: newActiveGameId,
          warning,
          successMessage,
        };
      }
      case 'ERROR': return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case actions.GAME_CANCEL: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loading: true,
      };
      case 'SUCCESS': {
        const game = action.data;

        const { newGames, newActiveGameId } = removeCurrentGame(state, game);

        const { warning, successMessage } = createMessages(state, action.response);

        return {
          ...state,
          loading: false,
          games: newGames,
          activeGameId: newActiveGameId,
          warning,
          successMessage,
        };
      }
      case 'ERROR': return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default reducer;
