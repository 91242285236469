import React from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Button, Col, Row, Table } from 'reactstrap';

import actions from '../../actions';
import { RefereeSearchInput } from '../main/FormInput';
import Status from '../main/Status';
import MessageModal from './MessageModal';
import RefereeListRow from './RefereeListRow';

interface StateProps {
  referees: ReadonlyArray<Referee>;
  refereeChoices: RefereeChoices;
  loading: boolean;
  error: Error | undefined;
  currentUser: User | undefined;
}

interface DispatchProps {
  fetchReferees: typeof actions.referee.fetchReferees;
  updateReferee: typeof actions.referee.updateReferee;
  sendRefereeMessage: typeof actions.referee.sendRefereeMessage;
  addRefereeGame: typeof actions.referee.addRefereeGame;
}

export interface OwnState {
  nameFilter: string;
  fieldFilter: string;
}

interface Props extends StateProps, DispatchProps {}

class RefereeList extends React.Component<Props, OwnState> {
  state: OwnState = {
    nameFilter: '',
    fieldFilter: '',
  };

  messageModalRef: React.RefObject<MessageModal>;

  constructor(props: Props) {
    super(props);

    this.messageModalRef = React.createRef();
  }

  componentDidMount() {
    this.props.fetchReferees();
  }

  filterList(e: React.ChangeEvent<HTMLInputElement>) {
    const searchWord = e.currentTarget.value;
    const target = e.currentTarget.name;
    this.setState({[target]: searchWord} as Pick<OwnState, keyof OwnState>);
  }

  updateReferee(referee: Referee, variableName: keyof Referee, value: string | number) {
    this.props.updateReferee(referee, variableName, value);
  }

  render(): JSX.Element {
    const { referees, refereeChoices, loading, error, currentUser, sendRefereeMessage, addRefereeGame } = this.props;
    const { nameFilter, fieldFilter } = this.state;

    let filteredReferees = referees.filter(
      (el) => el.user.first_name.toLowerCase()
      .concat(' ', el.user.last_name.toLowerCase(), ' ', el.user.username.toLowerCase())
      .includes(this.state.nameFilter.toLowerCase()));
    if (this.state.fieldFilter !== '') {
      filteredReferees = filteredReferees.filter(
        (el) => el.field !== null &&
        el.field.toString().startsWith(this.state.fieldFilter));
    }

    const listItems = filteredReferees.map((referee: Referee) => (
      <RefereeListRow
        referee={referee}
        refereeChoices={refereeChoices}
        currentUser={currentUser}
        key={referee.id}
        updateReferee={(variableName, value) =>
          this.updateReferee(referee, variableName, value)
        }
        sendRefereeMessage={sendRefereeMessage}
        addGame={addRefereeGame}
      />
    ));

    // TODO: Loading spinner could be in a better place
    return (
      <div>
        <Status loading={loading} error={error} />

        <MessageModal
          referee={undefined}
          currentUser={currentUser}
          sendMessage={sendRefereeMessage}
          ref={this.messageModalRef}
        />

        <h2>Tuomarit</h2>

        <Row>
          <Col xs="12" sm="4">
            <RefereeSearchInput
              name="nameFilter"
              label="Nimi tai käyttäjätunnus"
              type="text"
              value={nameFilter}
              onChange={(e) => this.filterList(e)}
            />
          </Col>
          <Col xs="12" sm="4">
            <RefereeSearchInput
              name="fieldFilter"
              label="Kenttä"
              type="number"
              value={fieldFilter}
              onChange= {(e) => this.filterList(e)}
            />
          </Col>
        </Row>

        <Button
            className="button-col"
            onClick={() => this.messageModalRef.current !== null && this.messageModalRef.current.show()}
          >
            Lähetä viesti kaikille tuomareille
        </Button>

        <Table>
          <thead>
            <tr>
              <th>Sukunimi</th>
              <th>Etunimi</th>
              <th>Käyttäjätunnus</th>
              <th>Kenttä</th>
              <th>Status</th>
              <th>Pantti</th>
              <th>Palkkio</th>
              <th>Kommentti</th>
              <th />
            </tr>
          </thead>
          <tbody>{listItems}</tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (
  state: State,
) => ({
  referees: state.referee.referees,
  refereeChoices: state.referee.refereeChoices,
  loading: state.referee.loadingReferees,
  error: state.referee.error,
  currentUser: state.currentUserDetail.user,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  fetchReferees: actions.referee.fetchReferees,
  updateReferee: actions.referee.updateReferee,
  sendRefereeMessage: actions.referee.sendRefereeMessage,
  addRefereeGame: actions.referee.addRefereeGame,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefereeList);
