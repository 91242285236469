import { Reducer } from 'redux';

import { RefereeAction } from '../types/actions';
import * as actions from '../types/actions';

const updateReferee = (state: Readonly<RefereeState>, newReferee: Readonly<Referee>): Referee[] => {
  const newReferees = state.referees.slice();
  const index = newReferees.findIndex((referee) => referee.id === newReferee.id);

  if (index === -1) {
    console.error(`Game with ID ${newReferee.id} not found in state`);
    return newReferees;
  }

  newReferees[index] = newReferee;

  return newReferees;
};

const initialState: RefereeState = {
  referees: [],
  refereeChoices: {} as RefereeChoices,
  loadingReferees: false,
  loadingRefereeChoices: false,
  error: undefined,
};

const reducer: Reducer<RefereeState, RefereeAction> = (state = initialState, action: RefereeAction): RefereeState => {
  switch (action.type) {
    case actions.FETCH_REFEREES: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loadingReferees: true,
      };
      case 'SUCCESS': return {
        ...state,
        loadingReferees: false,
        referees: action.data,
      };
      case 'ERROR': return {
        ...state,
        loadingReferees: false,
        error: action.error,
      };
    }
    case actions.FETCH_REFEREE_CHOICES: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loadingRefereeChoices: true,
      };
      case 'SUCCESS': return {
        ...state,
        loadingRefereeChoices: false,
        refereeChoices: action.data,
      };
      case 'ERROR': return {
        ...state,
        loadingRefereeChoices: false,
        error: action.error,
      };
    }
    case actions.UPDATE_REFEREE: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loadingReferees: true,
      };
      case 'SUCCESS': {
        const newReferees = updateReferee(state, action.data);

        return {
          ...state,
          loadingReferees: false,
          referees: newReferees,
        };
      }
      case 'ERROR': return {
        ...state,
        loadingReferees: false,
        error: action.error,
      };
    }
    case actions.SEND_REFEREE_MESSAGE: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loadingReferees: true,
      };
      case 'SUCCESS': return {
        ...state,
        loadingReferees: false,
      };
      case 'ERROR': return {
        ...state,
        loadingReferees: false,
        error: action.error,
      };
    }
    case actions.ADD_REFEREE_GAME: switch (action.status) {
      case 'PENDING': return {
        ...state,
        loadingReferees: true,
      };
      case 'SUCCESS': return {
        ...state,
        loadingReferees: false,
      };
      case 'ERROR': return {
        ...state,
        loadingReferees: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default reducer;
