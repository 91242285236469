import React from 'react';

import { connect, MapStateToProps } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

interface StateProps {
  loggedIn: boolean;
  loginTried: boolean;
  loading: boolean;
}

interface Props extends StateProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path: string | string[];
  exact?: boolean;
}

const ProtectedRoute: React.FunctionComponent<Props> = (props) => {
  const { loggedIn, loginTried, loading, path, exact, component } = props;
  if (!loginTried || loading) {
    return null; // TODO render spinner?
  }

  if (loggedIn) {
    return <Route path={path} exact={exact} component={component} />;
  } else {
    return <Redirect to="/" />;
  }
};

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  loggedIn: state.auth.loggedIn,
  loginTried: state.auth.loginTried,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {})(ProtectedRoute);
