import React, { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { Alert, Button, Form } from 'reactstrap';

import waxios from '../../actions/waxios';
import { jwtDecode } from '../../util/jwtDecode';
import { passwordValidator, repeatPasswordValidator } from '../../util/validators';
import ErrorMessage from './ErrorMessage';
import { PasswordResetInput } from './FormInput';
import SpinnerOverlay from './SpinnerOverlay';

interface RouteProps {
  match: {
    params: {
      token: string;
    };
  };
}

export interface OwnState {
  password: string;
  repeatPassword: string;
}

// Some really weird CSRF error shit happens if this is in the component
const verifyToken = async (token: string) => {
  try {
    const tokenResult: AxiosResponse<RefreshTokenResponse> = await waxios.post('token/verify', {
      token,
    });
    console.log(tokenResult);
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (token: string, password: string, userId: number) => {
  try {
    const tokenResult: AxiosResponse<RefreshTokenResponse> = await waxios.post('token/refresh', {
      refresh: token,
    });

    const result: AxiosResponse<any> = await waxios.patch(`/user/${userId}`, {
      password,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenResult.data.access}`,
      },
    });
    console.log(result);
  } catch (error) {
    throw error;
  }
};

const PasswordReset: React.FunctionComponent<RouteProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const { match: { params: { token }}} = props;

  useEffect(() => {
    const verify = async () => {
      setLoading(true);

      try {
        await verifyToken(token);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(error);
      }
    };

    verify();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!passwordValidator(password) || !repeatPasswordValidator(password, repeatPassword)) {
      return;
    }

    const jwtPayload: JWTPayload | undefined = jwtDecode(token);

    if (jwtPayload === undefined) {
      setError(new Error('Salasanaa ei voida uusia, koska annettu linkki ei toimi'));
      return;
    }

    setLoading(true);

    try {
      await resetPassword(token, password, jwtPayload.user_id);
      setLoading(false);
      setMessage('Salasanasi on vaihdettu. Voit kirjautua nyt sisään uudelleen.');
    } catch (error) {
      setError(error);
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <SpinnerOverlay visible={loading} />
      <h2>Vaihda salasana</h2>
      <ErrorMessage error={error}></ErrorMessage>
      {message !== '' && <Alert color="success">{message}</Alert>}
      <Form onSubmit={(e) => onSubmit(e)}>
        <PasswordResetInput
          name="password"
          label="Uusi salasana"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          required
          validator={passwordValidator}
          tip="Salasanan tulee olla vähintään 8 merkkiä pitkä, eikä saa koostua pelkästään numeroista"
        />
        <PasswordResetInput
          name="repeatPassword"
          label="Salasana uudestaan"
          type="password"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.currentTarget.value)}
          required
          valid={repeatPasswordValidator(password, repeatPassword)}
          feedback="Salasanojen tulee täsmätä"
        />
        <Button type="submit">Vaihda salasana</Button>
      </Form>
    </div>
  );
};

export default PasswordReset;
