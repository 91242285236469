import React, { useEffect, useState } from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';

import actions from '../../actions';

interface StateProps {
  loggedIn: boolean;
  currentUser: User | undefined;
}

interface DispatchProps {
  logout: typeof actions.auth.logout;
  fetchCurrentUser: typeof actions.user.fetchCurrentUser;
}

interface Props extends StateProps, DispatchProps {}

const Header: React.FunctionComponent<Props> = (props: Props) => {
  const [menuOpen, setMenuOpen] = useState(false); // React state hook, no reason to use Redux

  // useEffect React hook to observe changes in loggedIn state
  useEffect(() => {
    if (props.loggedIn) {
      props.fetchCurrentUser();
    }
  }, [props.loggedIn]);

  // Generic Nav item, using NavLink from react-router-dom
  const renderNavItem = (path: string, name: string) => {
    return (
      <NavItem onClick={() => setMenuOpen(false)}>
        <NavLink tag={RouterNavLink} to={path}>{name}</NavLink>
      </NavItem>
    );
  };

  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand tag={RouterNavLink} to="/">Tuho</NavbarBrand>
        <NavbarToggler onClick={() => setMenuOpen(!menuOpen)} />
        <Collapse isOpen={menuOpen} navbar>
          <Nav className="ml-auto" navbar>
            {
              props.currentUser !== undefined && props.currentUser.is_staff &&
              <React.Fragment>
                {renderNavItem('/users', 'Käyttäjät')}
                {renderNavItem('/referees', 'Tuomarit')}
                {renderNavItem('/teams', 'Joukkueet')}
              </React.Fragment>
            }

            {
              props.loggedIn ?
              <React.Fragment>
                {renderNavItem('/game-reporting', 'Tuloskilke')}
                {renderNavItem('/profile', 'Profiili')}
                <NavItem>
                  <NavLink
                    className="navbar-button"
                    role="button"
                    onClick={() => props.logout()}
                  >
                    Kirjaudu ulos
                  </NavLink>
                </NavItem>
              </React.Fragment>
              :
              <React.Fragment>
                {renderNavItem('/register', 'Rekisteröidy')}
                {renderNavItem('/', 'Kirjaudu')}
              </React.Fragment>
            }
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  loggedIn: state.auth.loggedIn,
  currentUser: state.currentUserDetail.user,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  logout: actions.auth.logout,
  fetchCurrentUser: actions.user.fetchCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
