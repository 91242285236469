import React from 'react';

import { CSSTransition } from 'react-transition-group';
import { Spinner as SpinnerElement } from 'reactstrap';

interface Props {
  visible: boolean;
}

const SpinnerOverlay: React.FunctionComponent<Props> = (props) => {
  return (
    <CSSTransition
      in={props.visible}
      timeout={500}
      classNames="fade"
      unmountOnExit
    >
      <div key="1" className="overlay">
          <SpinnerElement color="accent" className="spinner-large" />
      </div>
    </CSSTransition>
  );
};

export default SpinnerOverlay;
